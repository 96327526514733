import * as React from "react";
import { useForm } from "react-hook-form";
import { Field, Label, Input, Button, Message } from "pokko-shared";
import { strings } from "strings";
import { ProjectSettingsInput, ProjectSettingsProps } from "../ProjectSettings";

export const Rename: React.FC<ProjectSettingsProps> = ({
  value,
  save: [onSave, saveStatus],
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectSettingsInput>({
    defaultValues: value,
  });

  return (
    <form className="project-settings__name" onSubmit={handleSubmit(onSave)}>
      <Field>
        <Label>Project name</Label>
        <div className="project-settings__field-inline">
          <Input
            state={errors.name ? "danger" : undefined}
            {...register("name", { required: strings.required })}
          />
          <Button kind="secondary" type="submit" loading={saveStatus.loading}>
            Save
          </Button>
        </div>
        {errors.name?.message ? (
          <Message size="small" colour="danger">
            {errors.name.message}
          </Message>
        ) : null}
      </Field>
    </form>
  );
};
