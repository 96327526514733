import * as React from "react";
import { Helmet } from "react-helmet";
import { useListAccountsQuery, Account } from "api/graphql";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { AccountList } from "components/accounts/list/AccountList";

export const Home: React.FC = () => {
  const query = useListAccountsQuery();

  return (
    <SimpleLayout>
      <Helmet title="Accounts" />
      <AccountList
        loading={query.loading}
        accounts={(query.data?.accounts as Account[]) ?? []}
      />
    </SimpleLayout>
  );
};
