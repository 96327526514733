import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth";
import { UserProvider } from "./user";
import { HelmetProvider } from "./helmet";
import { ApiProvider, NoAuthApiProvider } from "./api";

const providers = [
  HelmetProvider,
  ApiProvider,
  AuthProvider,
  UserProvider,
  NoAuthApiProvider,
  BrowserRouter,
];

export const Providers: React.FC = ({ children }) =>
  React.createElement(
    React.Fragment,
    {},
    providers.reduce(
      (p, c) => React.createElement<any>(c, { children: p }),
      children
    )
  );
