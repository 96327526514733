import * as React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { ResetPasswordStep2 } from "./step2";
import { ResetPasswordStep1 } from "./step1";

export const Reset: React.FC = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");

  return (
    <SimpleLayout white>
      <Helmet title="Password reset" />
      {token ? <ResetPasswordStep2 token={token} /> : <ResetPasswordStep1 />}
    </SimpleLayout>
  );
};
