var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var CompleteIcon = function () { return (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "10", cy: "10", r: "10", fill: "#D5F5EE" }, void 0), _jsx("path", { d: "M5.71387 11.325L7.69489 13.2199C8.03575 13.546 8.57603 13.5351 8.90357 13.1957L14.2853 7.61902", stroke: "#4FC6A9", strokeWidth: "1.5" }, void 0)] }), void 0)); };
export var PauseIcon = function () { return (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "10", cy: "10", r: "10", fill: "#E2E4E8" }, void 0), _jsx("path", { d: "M7 13V7M13 13V7", stroke: "#8D95A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0)] }), void 0)); };
export var PlayIcon = function () { return (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "10", cy: "10", r: "10", fill: "#E2E4E8" }, void 0), _jsx("path", { d: "M7.31818 6C7.2338 6 7.15286 6.03512 7.09319 6.09763C7.03352 6.16014 7 6.24493 7 6.33333C7 6.33377 7 6.3342 7 6.33464V10V13.6654C7 13.6658 7 13.6662 7 13.6667C7 13.7551 7.03352 13.8399 7.09319 13.9024C7.15286 13.9649 7.2338 14 7.31818 14C7.38442 13.9998 7.44894 13.978 7.50275 13.9375L7.50337 13.9382L13.8142 10.3027C13.8696 10.2762 13.9165 10.2336 13.9494 10.1799C13.9824 10.1262 13.9999 10.0638 14 10C14 9.93441 13.9816 9.87027 13.947 9.81561C13.9124 9.76095 13.8632 9.7182 13.8055 9.69271L7.50337 6.06185H7.50275C7.44886 6.02163 7.38435 6.00001 7.31818 6Z", fill: "#8D95A5" }, void 0)] }), void 0)); };
export var StopIcon = function () { return (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "10", cy: "10", r: "10", fill: "#FDE2E3" }, void 0), _jsx("path", { d: "M7.14307 12.8572L12.8574 7.14288M12.8574 12.8572L7.14307 7.14288", stroke: "#F12B2C", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0)] }), void 0)); };
export var LoadingIcon = function () { return (_jsx("svg", __assign({ className: "icon__loading", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M16.5407 7.5C15.5332 4.8697 12.9835 3 10.0007 3C6.13699 3 3 6.13699 3 10.0007C3 13.8643 6.13699 17.0013 10.0007 17.0013C11.4871 17.0013 12.8659 16.537 14 15.7457", stroke: "white", strokeWidth: "2.25", strokeLinecap: "round", strokeLinejoin: "bevel" }, void 0) }), void 0)); };
