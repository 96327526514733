var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var SettingsIcon = function () { return (_jsxs("svg", __assign({ className: "icon__settings", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M18.9992 11.1073V8.85739L16.3214 8.41968C16.1627 7.78563 15.914 7.18961 15.5859 6.64351L17.1465 4.4194L15.5552 2.82935L13.3593 4.40795C12.812 4.0766 12.2119 3.8238 11.5733 3.66467L11.107 1H8.85713L8.42352 3.65567C7.78456 3.81234 7.18118 4.06188 6.63221 4.39159L4.44288 2.82731L2.85201 4.41736L4.39215 6.61978C4.05917 7.1708 3.80596 7.77335 3.64519 8.41641L1.00098 8.85657V11.1064L3.64233 11.5748C3.80187 12.2162 4.05508 12.8188 4.38929 13.371L2.82828 15.5542L4.41833 17.1459L6.62362 15.6013C7.17423 15.9318 7.77597 16.1834 8.41493 16.3409L8.85713 18.999H11.107L11.5799 16.3347C12.2156 16.1736 12.8161 15.9204 13.3614 15.5886L15.5818 17.1467L17.1723 15.5551L15.5871 13.3543C15.9152 12.8082 16.1643 12.2113 16.3214 11.5773L18.9992 11.1073Z", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), _jsx("path", { d: "M10.0005 7.13678C9.24111 7.13678 8.51282 7.43845 7.97585 7.97542C7.43888 8.51239 7.13721 9.24068 7.13721 10.0001C7.13721 10.7595 7.43888 11.4878 7.97585 12.0247C8.51282 12.5617 9.24111 12.8634 10.0005 12.8634C10.7599 12.8634 11.4882 12.5617 12.0252 12.0247C12.5621 11.4878 12.8638 10.7595 12.8638 10.0001C12.8638 9.24068 12.5621 8.51239 12.0252 7.97542C11.4882 7.43845 10.7599 7.13678 10.0005 7.13678Z", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0)); };
