import * as React from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { LinkButton, Button } from "pokko-shared";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import {
  useAcceptAccountInviteMutation,
  useGetAccountInviteQuery,
} from "api/graphql";

// TODO: componentise this.

export const AcceptInvitePage: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const qs = new URLSearchParams(search);
  const token = qs.get("token");

  const [accept, acceptStatus] = useAcceptAccountInviteMutation();

  const query = useGetAccountInviteQuery({
    skip: !token,
    variables: { token: token! },
  });

  const handleAccept = async () => {
    const res = await accept({ variables: { token: token! } });
    const account = res.data?.acceptAccountInvite;
    if (account) {
      navigate(`/accounts/${account}`);
    }
  };

  if (!token) {
    return <Navigate to="/" />;
  }

  if (query.loading) {
    return null;
  }

  if (!query.data?.accountInvite) {
    return <SimpleLayout>Invalid token</SimpleLayout>;
  }

  const invite = query.data.accountInvite;

  return (
    <SimpleLayout>
      {invite.existing ? (
        <>
          <p>You already have access to the '{invite.name}' account.</p>
          <LinkButton to={`/accounts/${invite.key}`}>
            {`Go to ${invite.name}`}
          </LinkButton>
        </>
      ) : (
        <>
          <p>You have been invited to join the '{invite.name}'</p>
          <Button loading={acceptStatus.loading} onClick={handleAccept}>
            Accept
          </Button>
        </>
      )}
    </SimpleLayout>
  );
};
