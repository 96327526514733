import * as React from "react";

export type User = {
  token: string;
};

type UserContextProps = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const loadUser = (): User | null => {
  try {
    const cookies: { [key: string]: string } = document.cookie
      .split("; ")
      .map((s) => s.split("="))
      .reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {});

    if (cookies["pok_token"]) {
      return { token: cookies["pok_token"] };
    }

    return null;
  } catch (ex) {
    return null;
  }
};

export const UserContext = React.createContext<UserContextProps>({
  user: null,
  setUser: () => {},
});

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(loadUser());

  const value: UserContextProps = {
    user,
    setUser: (val: User | null) => {
      if (val) {
        const oneWeek = 7 * 24 * 60 * 60;
        if (window.location.host.includes("pokko.io")) {
          document.cookie = `pok_token=${val.token}; domain=pokko.io; max-age=${oneWeek}; SameSite=None; Secure`;
        } else {
          document.cookie = `pok_token=${val.token}; max-age=${oneWeek};`;
        }
      } else {
        document.cookie = "pok_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      setUser(val);
    },
  };

  return React.createElement(UserContext.Provider, { value }, children);
};

export const useUser = () => React.useContext<UserContextProps>(UserContext);
