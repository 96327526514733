var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var SuccessIcon = function () { return (_jsx("svg", __assign({ className: "icon__check", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M1 11.7826L5.16015 15.7619C5.87595 16.4466 7.01054 16.4239 7.69838 15.7111L19 4", strokeWidth: "1.25" }, void 0) }), void 0)); };
export var SavedIcon = function () { return (_jsx("svg", __assign({ className: "icon__saved", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.2988 2C5.74891 2 2.05999 5.582 2.05999 10C2.05999 14.418 5.74891 18 10.2988 18C14.8487 18 18.5376 14.418 18.5376 10C18.5376 5.582 14.8487 2 10.2988 2ZM10.0457 12.9963L14.9519 8.02693L13.8845 6.97307L8.97826 11.9424C8.95981 11.9611 8.93006 11.9624 8.91013 11.9453L6.66755 10.0226L5.69123 11.1614L7.93381 13.084C8.55164 13.6137 9.47391 13.5754 10.0457 12.9963Z", fill: "#C4C4C4" }, void 0) }), void 0)); };
