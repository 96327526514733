import * as React from "react";
import { useNavigate } from "react-router";
import { useApolloClient } from "@apollo/client";
import {
  useLoginSocialMutation,
  useLoginLocalMutation,
  SamlUrlDocument,
  SamlUrlQuery,
  useRegisterLocalMutation,
  useLogoutMutation,
} from "api/graphql";
import { useUser } from "./user";
import { useSearchParams } from "react-router-dom";

const authUrlMap: { [hostname: string]: string } = {
  localhost: "http://localhost:5110",
  "app.pokko.io": "https://id.pokko.io",
};

export const authUrl =
  process.env.REACT_APP_AUTH_URL ??
  (typeof window !== "undefined"
    ? authUrlMap[window.location.hostname]
    : undefined) ??
  authUrlMap["app.pokko.io"];

type ErrorResponse = {
  message: string;
  code?: string;
};

type Status = {
  loading: boolean;
  error?: ErrorResponse;
};

type Login = {
  login: (email: string, password: string) => Promise<void>;
  status: Status;
};
type LoginSocial = {
  loginSocial: (provider: string, token: string) => Promise<void>;
  status: Status;
};
type Register = {
  register: (email: string, password: string, name: string) => Promise<void>;
  status: Status;
};
type LoginSsoStatus = Status & {
  url?: string;
  multiple?: boolean;
  found?: boolean;
};
type LoginSso = {
  loginSso: (email: string) => Promise<void>;
  status: LoginSsoStatus;
};
type Logout = { logout: () => Promise<void>; status: Status };

type AuthContextProps = {
  login: Login;
  loginSocial: LoginSocial;
  loginSso: LoginSso;
  register: Register;
  logout: Logout;
};

const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const { user, setUser } = useUser();

  const [login, loginStatus] = useLoginLocalMutation();
  const [loginSocial, loginSocialStatus] = useLoginSocialMutation();
  const [logout, logoutStatus] = useLogoutMutation();
  const [register, registerStatus] = useRegisterLocalMutation();
  const client = useApolloClient();

  const [loginSsoStatus, setLoginSsoStatus] = React.useState<LoginSsoStatus>({
    loading: false,
  });

  const returnUrl = search.get("returnUrl");
  React.useEffect(() => {
    if (returnUrl) {
      sessionStorage["returnUrl"] = returnUrl;
    }
  }, [returnUrl]);

  const loginSuccess = (token: string) => {
    setUser({ token });

    if (sessionStorage["returnUrl"]) {
      window.location.href = sessionStorage["returnUrl"];
    } else {
      navigate("/accounts");
    }
  };

  const value: AuthContextProps = {
    login: {
      login: async (email: string, password: string) => {
        try {
          const res = await login({ variables: { email, password } });

          if (res.data?.local?.token) {
            loginSuccess(res.data?.local?.token);
          }
        } catch (ex) {
          console.warn(ex);
        }
      },
      status: loginStatus,
    },
    loginSocial: {
      loginSocial: async (provider: string, token: string) => {
        try {
          const res = await loginSocial({ variables: { provider, token } });

          if (res.data?.social?.token) {
            loginSuccess(res.data?.social?.token);
          }
        } catch (ex) {
          console.warn(ex);
        }
      },
      status: loginSocialStatus,
    },
    loginSso: {
      loginSso: async (email: string) => {
        setLoginSsoStatus({ loading: true });
        try {
          const res = await client.query<SamlUrlQuery>({
            query: SamlUrlDocument,
            variables: { email },
          });

          if (res.data.saml?.url) {
            setLoginSsoStatus({
              loading: false,
              found: true,
              url: res.data.saml.url,
            });
          } else {
            setLoginSsoStatus({
              loading: false,
              found: true,
              multiple: true,
            });
          }
        } catch (ex) {
          setLoginSsoStatus({
            loading: false,
            error: {
              message: "No identity provider found for this email address.",
            },
          });
        }
      },
      status: loginSsoStatus,
    },
    register: {
      register: async (email: string, password: string, name: string) => {
        try {
          const res = await register({ variables: { name, email, password } });

          if (res.data?.register?.token) {
            loginSuccess(res.data?.register?.token);
          }
        } catch (ex) {
          console.warn(ex);
        }
      },
      status: registerStatus,
    },
    logout: {
      logout: async () => {
        if (user?.token) {
          await logout({ variables: { token: user.token } });
        }
        setUser(null);
        navigate("/login");
      },
      status: logoutStatus,
    },
  };

  return React.createElement(AuthContext.Provider, { value }, children);
};

export const useAuth = () => React.useContext<AuthContextProps>(AuthContext);
