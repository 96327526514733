var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var SearchIcon = function () { return (_jsx("svg", __assign({ className: "icon__search", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M12.1135 12.1134C9.71194 14.5149 5.82642 14.5232 3.43498 12.1317C1.04353 9.74027 1.05174 5.85475 3.45332 3.45318C5.85489 1.0516 9.74041 1.04339 12.1319 3.43483C14.5233 5.82628 14.5151 9.7118 12.1135 12.1134ZM12.1135 12.1134L16.9689 16.9688", strokeWidth: "1.25" }, void 0) }), void 0)); };
