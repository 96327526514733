import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router";

import { Account, useGetAccountQuery } from "api/graphql";
import { AccountHome } from "./home";
import { AccountSharingPage } from "./sharing";
import { AccountSettingsPage } from "./settings";
import { ProjectCreatePage } from "./projects/create";
import { ProjectSettingsPage } from "./projects/settings";
import { AccountUsagePage } from "./usage";

export type AccountContextProps = {
  account: Account;
  reload: () => void;
  loading: boolean;
};

export const AccountContext = React.createContext<AccountContextProps>({
  account: {} as any,
  reload: () => {},
  loading: false,
});

export const useAccount = (): AccountContextProps =>
  React.useContext<AccountContextProps>(AccountContext);

export const AccountPage: React.FC = () => {
  const { account } = useParams();
  const query = useGetAccountQuery({ variables: { key: account! } });

  if (query.loading) {
    return null;
  }

  if (!query.data?.account) {
    return <Navigate to="/" />;
  }

  const props: AccountContextProps = {
    account: query.data.account as Account,
    reload: () => query.refetch(),
    loading: query.loading,
  };

  return (
    <AccountContext.Provider value={props}>
      <Routes>
        <Route element={<AccountHome />} />
        <Route path="sharing" element={<AccountSharingPage />} />
        <Route path="settings" element={<AccountSettingsPage />} />
        <Route path="usage" element={<AccountUsagePage />} />

        <Route path="projects/create" element={<ProjectCreatePage />} />
        <Route
          path="projects/:project/settings"
          element={<ProjectSettingsPage />}
        />
      </Routes>
    </AccountContext.Provider>
  );
};
