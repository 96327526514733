import * as React from "react";
import { LinkButton, Icons } from "pokko-shared";
import { Rename } from "./components/Rename";

export type AccountSettingsInput = {
  name: string;
};

export type AccountUser = {
  id: string;
  name: string;
  email: string;
};

export type AccountSettingsProps = {
  name: string;
  accountKey: string;
  value: AccountSettingsInput;
  users: AccountUser[];
  save: [(value: AccountSettingsInput) => void, { loading: boolean }];
  onRemoveUser: (id: string) => void;
};

export const AccountSettings: React.FC<AccountSettingsProps> = (props) => {
  const { accountKey, name, users, onRemoveUser } = props;

  return (
    <div className="account-settings__container">
      <div className="account-settings__header">
        <h3 className="h3">{name} settings</h3>
        <LinkButton
          small
          kind="tertiary"
          to=".."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to account
        </LinkButton>
      </div>
      <Rename {...props} />
      <hr />
      <div className="account-settings__users">
        <h4 className="header">Account users</h4>
        <ul className="account-settings__user-list">
          {users.map((ent) => (
            <AccountUserRow
              key={ent.id}
              user={ent}
              onRemoveUser={users.length === 1 ? undefined : onRemoveUser}
            />
          ))}
        </ul>
      </div>
      <hr />
      <div className="account-settings__users">
        <h4 className="header">Danger zone</h4>
        <p>If you would like to close your account, please get in touch.</p>
        <p>
          Email us at{" "}
          <a
            href={`mailto:support@pokko.io?subject=Close account ${accountKey}`}
          >
            support@pokko.io
          </a>{" "}
          quoting account reference {accountKey}.
        </p>
      </div>
    </div>
  );
};

const AccountUserRow: React.FC<{
  user: AccountUser;
  onRemoveUser?: (id: string) => void;
}> = ({ user, onRemoveUser }) => {
  const [deleting, setDeleting] = React.useState(false);

  return (
    <li key={user.id}>
      <span>{user.name || user.email}</span>
      <span />
      {onRemoveUser ? (
        deleting ? (
          <>
            <div className="account-settings__user-list-actions">
              <button key="confirm" onClick={() => onRemoveUser(user.id)}>
                Confirm
              </button>
              <button key="cancel" onClick={() => setDeleting(false)}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <button key="remove" onClick={() => setDeleting(true)}>
            Remove
          </button>
        )
      ) : null}
    </li>
  );
};
