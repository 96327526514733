import * as React from "react";
import { LinkButton, Icons } from "pokko-shared";
import {
  ProjectCreateEmpty,
  ProjectCreateEmptyProps,
} from "./components/CreateEmpty";
import {
  ProjectCreateImport,
  ProjectCreateImportProps,
} from "./components/CreateImport";
import {
  ProjectCreateTemplated,
  ProjectCreateTemplatedProps,
} from "./components/CreateTemplated";
import { EmptyIcon, ImportIcon, TemplateIcon } from "./icons";

export type CreateProjectInput = {
  name: string;
  region: string;
};

export type Region = {
  id: string;
  name: string;
};

export type ProjectCreateProps = {
  empty?: ProjectCreateEmptyProps;
  templated?: ProjectCreateTemplatedProps;
  imported?: ProjectCreateImportProps;
  regions: Region[];
};

type ProjectCreateType = "empty" | "templated" | "imported";

export const ProjectCreate: React.FC<ProjectCreateProps> = ({
  empty,
  imported,
  regions,
  templated,
}) => {
  const [type, setType] = React.useState<ProjectCreateType | undefined>();

  return (
    <div className="project-create__container">
      <div className="project-create__header">
        <h1 className="h2">Create a project</h1>
        <LinkButton
          small
          kind="tertiary"
          to="../.."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to account
        </LinkButton>
      </div>
      {!type ? (
        <div className="project-create__options--container">
          <p>How would you like to start your project?</p>
          <div className="project-create__options--list">
            <button onClick={() => setType("empty")}>
              <EmptyIcon />
              <span>Empty project</span>
            </button>
            <button onClick={() => setType("imported")}>
              <ImportIcon />
              <span>Import</span>
            </button>
            <button disabled>
              <TemplateIcon />
              <span>From a template</span>
              <small>Coming soon</small>
            </button>
          </div>
        </div>
      ) : null}
      {empty && type === "empty" ? (
        <ProjectCreateEmpty
          regions={regions}
          onBack={() => setType(undefined)}
          {...empty}
        />
      ) : null}
      {templated && type === "templated" ? (
        <ProjectCreateTemplated
          regions={regions}
          onBack={() => setType(undefined)}
          {...templated}
        />
      ) : null}
      {imported && type === "imported" ? (
        <ProjectCreateImport
          regions={regions}
          onBack={() => setType(undefined)}
          {...imported}
        />
      ) : null}
    </div>
  );
};
