import * as React from "react";
import { Button, IconButton, SplitButton, Icons } from "pokko-shared";

export const Components: React.FC = () => (
  <div>
    <h1>Buttons</h1>
    <div>
      <table>
        <thead>
          <tr>
            <th>Green</th>
            <th>Enabled</th>
            <th>Disabled</th>
            <th>Hover</th>
            <th>Focussed</th>
            <th>Loading</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Primary no icon</td>
            <td>
              <Button kind="primary">Button</Button>
            </td>
            <td>
              <Button kind="primary" disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Primary small</td>
            <td>
              <Button kind="primary" small>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" small disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" small hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" small focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="primary" small loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Primary right icon</td>
            <td>
              <Button kind="primary" icons={{ right: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ right: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ right: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ right: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ right: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Primary left icon</td>
            <td>
              <Button kind="primary" icons={{ left: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ left: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ left: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ left: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{ left: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Primary right + left icon</td>
            <td>
              <Button
                kind="primary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="primary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Primary icon only</td>
            <td>
              <IconButton kind="primary">
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="primary" disabled>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="primary" hover>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="primary" focus>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="primary" loading>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
          </tr>
          <tr>
            <td>Primary addon</td>
            <td>
              <SplitButton kind="primary">Button</SplitButton>
            </td>
            <td>
              <SplitButton kind="primary" disabled>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="primary" hover>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="primary" focus>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="primary" loading>
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Primary addon right icon</td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ right: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ right: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ right: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ right: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ right: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Primary addon left icon</td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ left: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ left: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ left: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ left: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="primary"
                icons={{ left: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>
          {/*secondary*/}
          <tr>
            <td>Secondary no icon</td>
            <td>
              <Button kind="secondary">Button</Button>
            </td>
            <td>
              <Button kind="secondary" disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Secondary small</td>
            <td>
              <Button kind="secondary" small>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" small disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" small hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" small focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="secondary" small loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Secondary right icon</td>
            <td>
              <Button kind="secondary" icons={{ right: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ right: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ right: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ right: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ right: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Secondary left icon</td>
            <td>
              <Button kind="secondary" icons={{ left: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ left: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ left: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ left: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{ left: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Secondary right + left icon</td>
            <td>
              <Button
                kind="secondary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="secondary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Secondary icon only</td>
            <td>
              <IconButton kind="secondary">
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="secondary" disabled>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="secondary" hover>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="secondary" focus>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="secondary" loading>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
          </tr>
          <tr>
            <td>Secondary addon</td>
            <td>
              <SplitButton kind="secondary">Button</SplitButton>
            </td>
            <td>
              <SplitButton kind="secondary" disabled>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="secondary" hover>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="secondary" focus>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="secondary" loading>
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Secondary addon right icon</td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ right: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ right: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ right: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ right: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ right: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Secondary addon left icon</td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ left: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ left: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ left: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ left: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="secondary"
                icons={{ left: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>

          {/*Tertiary*/}
          <tr>
            <td>Tertiary no icon</td>
            <td>
              <Button kind="tertiary">Button</Button>
            </td>
            <td>
              <Button kind="tertiary" disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Tertiary small</td>
            <td>
              <Button kind="tertiary" small>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" small disabled>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" small hover>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" small focus>
                Button
              </Button>
            </td>
            <td>
              <Button kind="tertiary" small loading>
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Tertiary right icon</td>
            <td>
              <Button kind="tertiary" icons={{ right: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ right: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ right: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ right: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ right: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Tertiary left icon</td>
            <td>
              <Button kind="tertiary" icons={{ left: <Icons.SearchIcon /> }}>
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ left: <Icons.SearchIcon /> }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ left: <Icons.SearchIcon /> }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ left: <Icons.SearchIcon /> }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{ left: <Icons.SearchIcon /> }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Tertiary right + left icon</td>
            <td>
              <Button
                kind="tertiary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                disabled
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                hover
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                focus
              >
                Button
              </Button>
            </td>
            <td>
              <Button
                kind="tertiary"
                icons={{
                  left: <Icons.SuccessIcon />,
                  right: <Icons.NextIcon />,
                }}
                loading
              >
                Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Tertiary icon only</td>
            <td>
              <IconButton kind="tertiary">
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="tertiary" disabled>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="tertiary" hover>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="tertiary" focus>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
            <td>
              <IconButton kind="tertiary" loading>
                <Icons.SettingsIcon />
              </IconButton>
            </td>
          </tr>
          <tr>
            <td>Tertiary addon</td>
            <td>
              <SplitButton kind="tertiary">Button</SplitButton>
            </td>
            <td>
              <SplitButton kind="tertiary" disabled>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="tertiary" hover>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="tertiary" focus>
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton kind="tertiary" loading>
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Tertiary addon right icon</td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ right: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ right: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ right: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ right: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ right: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>
          <tr>
            <td>Tertiary addon left icon</td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ left: <Icons.SuccessIcon /> }}
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ left: <Icons.SuccessIcon /> }}
                disabled
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ left: <Icons.SuccessIcon /> }}
                hover
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ left: <Icons.SuccessIcon /> }}
                focus
              >
                Button
              </SplitButton>
            </td>
            <td>
              <SplitButton
                kind="tertiary"
                icons={{ left: <Icons.SuccessIcon /> }}
                loading
              >
                Button
              </SplitButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
