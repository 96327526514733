import * as React from "react";
import { useNavigate } from "react-router";
import { useCreateAccountMutation, useListAccountsQuery } from "api/graphql";
import {
  AccountCreateBasic,
  AccountCreateBasicInput,
} from "components/accounts/create/AccountCreateBasic";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { Helmet } from "react-helmet";

export const currencySymbolMap: { [key: string]: string } = {
  usd: "$",
  aud: "$",
  eur: "€",
  gbp: "£",
};

export const AccountCreatePage: React.FC = () => {
  const [create, createStatus] = useCreateAccountMutation();
  const query = useListAccountsQuery();
  const navigate = useNavigate();

  const handleSubmit = async (values: AccountCreateBasicInput) => {
    const res = await create({
      variables: {
        input: values,
      },
    });

    await query.refetch();

    navigate(`/accounts/${res.data?.createAccount?.key}`);
  };

  return (
    <SimpleLayout>
      <Helmet title="New account" />
      <AccountCreateBasic
        loading={createStatus.loading}
        onSubmit={handleSubmit}
      />
    </SimpleLayout>
  );
};
