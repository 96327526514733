var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { LoadingIcon } from "../../../icons/ui/uploads";
export var Button = React.forwardRef(function (_a, ref) {
    var _b;
    var children = _a.children, icons = _a.icons, kind = _a.kind, loading = _a.loading, small = _a.small, hover = _a.hover, focus = _a.focus, rest = __rest(_a, ["children", "icons", "kind", "loading", "small", "hover", "focus"]);
    return (_jsxs("button", __assign({ ref: ref, className: cx("button", (_b = {},
            _b["--" + kind] = !!kind,
            _b["--small"] = small,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--loading"] = loading,
            _b["--text-only"] = !((icons === null || icons === void 0 ? void 0 : icons.right) || (icons === null || icons === void 0 ? void 0 : icons.left)),
            _b["--has-icon-left"] = icons === null || icons === void 0 ? void 0 : icons.left,
            _b["--has-icon-right"] = loading || (icons === null || icons === void 0 ? void 0 : icons.right),
            _b["--has-icon-both"] = (loading || (icons === null || icons === void 0 ? void 0 : icons.right)) && (icons === null || icons === void 0 ? void 0 : icons.left),
            _b)) }, rest, { children: [(icons === null || icons === void 0 ? void 0 : icons.left) ? (_jsx("div", __assign({ className: "button__icon--left" }, { children: icons.left }), void 0)) : null, _jsx("div", __assign({ className: "button__content" }, { children: children }), void 0), loading ? (_jsx("div", __assign({ className: "button__icon--loading" }, { children: _jsx(LoadingIcon, {}, void 0) }), void 0)) : (icons === null || icons === void 0 ? void 0 : icons.right) ? (_jsx("div", __assign({ className: "button__icon--right" }, { children: icons === null || icons === void 0 ? void 0 : icons.right }), void 0)) : null] }), void 0));
});
export var LinkButton = function (_a) {
    var _b;
    var children = _a.children, icons = _a.icons, kind = _a.kind, loading = _a.loading, small = _a.small, focus = _a.focus, hover = _a.hover, rest = __rest(_a, ["children", "icons", "kind", "loading", "small", "focus", "hover"]);
    return (_jsxs(NavLink, __assign({ className: cx("button", (_b = {},
            _b["--" + kind] = !!kind,
            _b["--small"] = small,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--loading"] = loading,
            _b["--text-only"] = !((icons === null || icons === void 0 ? void 0 : icons.right) || (icons === null || icons === void 0 ? void 0 : icons.left)),
            _b["--has-icon-left"] = icons === null || icons === void 0 ? void 0 : icons.left,
            _b["--has-icon-right"] = loading || (icons === null || icons === void 0 ? void 0 : icons.right),
            _b["--has-icon-both"] = (loading || (icons === null || icons === void 0 ? void 0 : icons.right)) && (icons === null || icons === void 0 ? void 0 : icons.left),
            _b)) }, rest, { children: [(icons === null || icons === void 0 ? void 0 : icons.left) ? (_jsx("div", __assign({ className: "button__icon--left" }, { children: icons.left }), void 0)) : null, _jsx("div", __assign({ className: "button__content" }, { children: children }), void 0), loading ? (_jsx("div", __assign({ className: "button__icon--loading" }, { children: _jsx(LoadingIcon, {}, void 0) }), void 0)) : (icons === null || icons === void 0 ? void 0 : icons.right) ? (_jsx("div", __assign({ className: "button__icon--right" }, { children: icons === null || icons === void 0 ? void 0 : icons.right }), void 0)) : null] }), void 0));
};
