import * as React from "react";
import { Helmet, HelmetProps } from "react-helmet";

export const HelmetProvider: React.FC = ({ children }) =>
  React.createElement(React.Fragment, {
    children: [
      React.createElement(React.Fragment, {
        key: 0,
        children: React.createElement<HelmetProps>(Helmet, {
          defaultTitle: "Pokko",
          titleTemplate: "%s - Pokko",
        }),
      }),
      React.createElement(
        React.Fragment,
        {
          key: 1,
        },
        children
      ),
    ],
  });
