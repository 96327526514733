import React from "react";
import { AppRoutes } from "./routes";
import { Providers } from "providers";

import "./app.scss";

export const App: React.FC = () => (
  <Providers>
    <AppRoutes />
  </Providers>
);
