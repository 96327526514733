import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputField, Message, Button } from "pokko-shared";

import { FormProps } from "../defaults";

export type ResetStep2Input = {
  password: string;
};

export type ResetStep2FormProps = FormProps<ResetStep2Input> & {
  success: boolean;
};

export const PasswordResetStep2Form: React.FC<ResetStep2FormProps> = ({
  defaultValues,
  error,
  loading,
  success,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ResetStep2Input>({
    defaultValues,
  });

  React.useEffect(() => reset(), [reset]);

  return (
    <div className="entry-form">
      <h2 className="h2 entry-form__heading">Reset your password</h2>

      <p className="entry-form__intro">Choose a new password.</p>

      <form className="entry-form__input" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="password"
          placeholder="New password"
          disabled={loading}
          error={errors.password}
          autoFocus
          {...register("password", {
            required: "This is a required field",
            minLength: {
              value: 6,
              message: "Passwords must be at least six characters long",
            },
          })}
        />

        {error ? (
          <div className="entry-form__message">
            <Message size="small" colour="danger">
              <p>{error}</p>
            </Message>
          </div>
        ) : null}

        {success ? (
          <div className="entry-form__message">
            <Message size="small" colour="success">
              <p>Your password has been reset</p>
            </Message>
          </div>
        ) : null}

        <Button kind="primary" loading={loading} type="submit">
          Reset and login
        </Button>
      </form>

      <div className="entry-form__footer">
        <small>
          Remember your password? <Link to="/login">Try logging in</Link>
        </small>
      </div>
    </div>
  );
};
