var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var MoreIcon = function () { return (_jsx("svg", __assign({ className: "icon__more", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M8.66658 10C8.66658 10.7365 9.26347 11.3333 9.99992 11.3333C10.7364 11.3333 11.3333 10.7365 11.3333 10C11.3333 9.26356 10.7364 8.66667 9.99992 8.66667C9.26347 8.66667 8.66658 9.26356 8.66658 10ZM8.66658 14.6667C8.66658 15.4031 9.26347 16 9.99992 16C10.7364 16 11.3333 15.4031 11.3333 14.6667C11.3333 13.9302 10.7364 13.3333 9.99992 13.3333C9.26347 13.3333 8.66658 13.9302 8.66658 14.6667ZM8.66659 5.33333C8.66659 6.06978 9.26347 6.66667 9.99992 6.66667C10.7364 6.66667 11.3333 6.06978 11.3333 5.33333C11.3333 4.59689 10.7364 4 9.99992 4C9.26347 4 8.66659 4.59689 8.66659 5.33333Z", fill: "black" }, void 0) }), void 0)); };
