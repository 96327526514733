import * as React from "react";
import { Helmet } from "react-helmet";
import { LoginForm } from "../../components/entry/login/LoginForm";
import { SimpleLayout } from "../../components/layout/simple/SimpleLayout";

import { useLogin } from "./hook";

export const Login: React.FC = () => {
  const {
    error,
    googleClientId,
    handleSocialLogin,
    handleSubmit,
    loading,
  } = useLogin();

  return (
    <SimpleLayout white>
      <Helmet title="Log in" />
      <LoginForm
        error={error}
        defaultValues={{
          email: localStorage.getItem("pok:login:email") || "",
          password: "",
        }}
        googleClientId={googleClientId}
        loading={loading}
        onSocial={handleSocialLogin}
        onSubmit={handleSubmit}
      />
    </SimpleLayout>
  );
};
