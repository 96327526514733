var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import cx from "classnames";
import { Control } from "../field/Field";
import { DangerIcon } from "../../../icons/ui/danger";
import { DisabledIcon } from "../../../icons/ui/disabled";
import { SearchIcon } from "../../../icons/ui/search";
import { SuccessIcon } from "../../../icons/ui/success";
export var Input = React.forwardRef(function (_a, ref) {
    var _b;
    var state = _a.state, className = _a.className, help = _a.help, onChangeText = _a.onChangeText, addOn = _a.addOn, rest = __rest(_a, ["state", "className", "help", "onChangeText", "addOn"]);
    var handleChange = function (ev) {
        rest.onChange && rest.onChange(ev);
        onChangeText && onChangeText(ev.currentTarget.value);
    };
    var inputEl = (_jsx("input", __assign({ ref: ref, className: cx("input", className, (_b = {},
            _b["is-" + state] = !!state,
            _b)), onChange: handleChange }, rest), void 0));
    return (_jsxs(Control, __assign({ iconLeft: rest.type === "search" ? _jsx(SearchIcon, {}, void 0) : null, iconRight: rest.disabled ? (_jsx(DisabledIcon, {}, void 0)) : state === "danger" ? (_jsx(DangerIcon, {}, void 0)) : state === "success" ? (_jsx(SuccessIcon, {}, void 0)) : null }, { children: [addOn ? (_jsxs("div", __assign({ className: "input__addon-container" }, { children: [addOn === null || addOn === void 0 ? void 0 : addOn.left, inputEl, addOn === null || addOn === void 0 ? void 0 : addOn.right] }), void 0)) : (inputEl), help ? _jsx("small", __assign({ className: "help" }, { children: help }), void 0) : null] }), void 0));
});
