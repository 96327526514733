import { useAuth } from "providers/auth";
import { LocalInput } from "../../components/entry/login/LoginForm";

export const useLogin = () => {
  const {
    login: { login, status: loginStatus },
    loginSocial: { loginSocial, status: loginSocialStatus },
  } = useAuth();

  const handleSubmit = async ({ email, password }: LocalInput) => {
    localStorage.setItem("pok:login:email", email);

    await login(email, password);
  };

  const handleSocialLogin = async (provider: string, response: any) => {
    switch (provider) {
      case "google":
        const idToken = response.getAuthResponse().id_token;

        await loginSocial(provider, idToken);

        break;
    }
  };

  return {
    handleSocialLogin,
    handleSubmit,
    loading: loginSocialStatus.loading || loginStatus.loading,
    error: (loginStatus.error ?? loginSocialStatus.error)?.message,
    googleClientId:
      "209601467929-v2fravjk4facf23dhh7am1h05hg3dtbe.apps.googleusercontent.com",
  };
};
