import * as React from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "providers/auth";

import { SsoLoginForm } from "components/entry/sso/SsoLoginForm";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

export const SsoLogin: React.FC = () => {
  const {
    loginSso: { loginSso, status },
  } = useAuth();

  if (status.url) {
    window.location.href = status.url;
  }

  const message =
    status.found === false
      ? `We couldn't find any SSO details related to this email address`
      : status.multiple === true
      ? `We found multiple SSO accounts associated with this email, use your identity provider to login and access your account`
      : status.url
      ? "Redirecting you now"
      : undefined;

  return (
    <SimpleLayout white>
      <Helmet title="SSO Log in" />
      <SsoLoginForm
        loading={status.loading}
        onSubmit={(data) => loginSso(data.email)}
        error={status.error?.message}
        message={message}
        messageColour={status.url ? "success" : undefined}
      />
    </SimpleLayout>
  );
};
