var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from "classnames";
export var Message = function (_a) {
    var _b;
    var colour = _a.colour, size = _a.size, children = _a.children;
    return (_jsx("div", __assign({ className: cx("message", (_b = {},
            _b["is-" + colour] = colour,
            _b["is-" + size] = size,
            _b)) }, { children: children }), void 0));
};
