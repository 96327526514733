import * as React from "react";
import { Helmet } from "react-helmet";

import { RegisterForm } from "components/entry/register/RegisterForm";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

import { useRegister } from "./hook";

export const Register: React.FC = () => {
  const {
    error,
    googleClientId,
    handleSocialLogin,
    handleSubmit,
    loading,
  } = useRegister();

  return (
    <SimpleLayout white>
      <Helmet title="Register" />
      <RegisterForm
        error={error}
        googleClientId={googleClientId}
        loading={loading}
        onSocial={handleSocialLogin}
        onSubmit={handleSubmit}
      />
    </SimpleLayout>
  );
};
