import * as React from "react";
import { useNavigate } from "react-router";

import { useUser } from "providers/user";

import {
  ResetStep2Input,
  PasswordResetStep2Form,
} from "components/entry/reset/PasswordResetStep2Form";
import { usePasswordResetMutation } from "api/graphql";

export const ResetPasswordStep2: React.FC<{ token: string }> = ({ token }) => {
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState(false);
  const [reset, resetStatus] = usePasswordResetMutation();

  const { setUser } = useUser();

  const handleSubmit = async ({ password }: ResetStep2Input) => {
    try {
      const res = await reset({ variables: { token, password } });
      if (res.data?.passwordReset?.token) {
        setUser({ token: res.data.passwordReset.token });
        navigate("/");
        setSuccess(true);
      }
    } catch (ex) {
      console.warn(ex);
    }
  };

  return (
    <PasswordResetStep2Form
      loading={resetStatus.loading}
      success={success}
      error={resetStatus.error?.message}
      onSubmit={handleSubmit}
    />
  );
};
