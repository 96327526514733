import { RegisterInput } from "components/entry/register/RegisterForm";
import { useAuth } from "providers/auth";

export const useRegister = () => {
  const {
    register: { register, status: registerStatus },
    loginSocial: { loginSocial, status: loginSocialStatus },
  } = useAuth();

  const handleSubmit = async ({ email, password, name }: RegisterInput) => {
    localStorage.setItem("pok:login:email", email);

    await register(email, password, name);
  };

  const handleSocialLogin = async (provider: string, response: any) => {
    switch (provider) {
      case "google":
        const idToken = response.getAuthResponse().id_token;

        await loginSocial(provider, idToken);

        break;
    }
  };

  return {
    handleSubmit,
    handleSocialLogin,
    loading: registerStatus.loading || loginSocialStatus.loading,
    error: (registerStatus.error ?? loginSocialStatus.error)?.message,
    googleClientId:
      "209601467929-v2fravjk4facf23dhh7am1h05hg3dtbe.apps.googleusercontent.com",
  };
};
