import * as React from "react";
export var useDetectOutsideClick = function (el, initialState) {
    var _a = React.useState(initialState), isActive = _a[0], setIsActive = _a[1];
    React.useEffect(function () {
        var pageClickEvent = function (ev) {
            // If the active element exists and is clicked outside of
            if (el.current !== null && !el.current.contains(ev.target)) {
                setIsActive(!isActive);
            }
        };
        // If the item is active (ie open) then listen for clicks
        if (isActive) {
            window.addEventListener("click", pageClickEvent);
        }
        return function () {
            window.removeEventListener("click", pageClickEvent);
        };
    }, [isActive, el]);
    return [isActive, setIsActive];
};
