var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var MoveIcon = function () { return (_jsxs("svg", __assign({ className: "icon__move", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M19 10H1M19 6H1H19Z", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10" }, void 0), _jsx("path", { d: "M1 14H19", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10" }, void 0), _jsx("path", { d: "M7 16.7778L10 19L13 16.7778", stroke: "black", strokeWidth: "1.25", strokeLinecap: "round" }, void 0), _jsx("path", { d: "M7 3.22222L10 1L13 3.22222", stroke: "black", strokeWidth: "1.25", strokeLinecap: "round" }, void 0)] }), void 0)); };
