import * as React from "react";

import { Input, Button, LinkButton, Icons } from "pokko-shared";

type Account = {
  key: string;
  name: string;
};

type InviteLink = {
  id: string;
  url: string;
  onRemove: () => void;
  created: Date;
};

export type AccountSharingProps = {
  account: Account;
  links: InviteLink[];
  onCreate: () => void;
};

const timeAgo = (dt: Date): string => {
  const ms = new Date().getTime() - dt.getTime();
  const sec = Math.floor(ms / 1000);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (sec < 70) {
    return rtf.format(-sec, "seconds");
  } else {
    const min = Math.floor(sec / 60);

    if (min < 60) {
      return rtf.format(-min, "minutes");
    } else {
      const hr = Math.floor(min / 60);

      if (hr < 24) {
        return rtf.format(-hr, "hours");
      } else {
        const day = Math.floor(hr / 24);

        return rtf.format(-day, "days");
      }
    }
  }
};

const AccountSharingRow: React.FC<{ ent: InviteLink }> = ({ ent }) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const handleCopy = () => {
    inputRef.current?.select();
    document.execCommand("copy");
  };

  return (
    <li key={ent.id}>
      <div className="account-sharing__link">
        <div className="account-sharing__link-field">
          <Input
            type="url"
            value={ent.url}
            readOnly
            onClick={handleCopy}
            ref={inputRef}
          />

          <div className="account-sharing__status">
            <small>Created {timeAgo(ent.created)}</small>

            <Button kind="tertiary" small onClick={ent.onRemove}>
              Remove link
            </Button>
          </div>
        </div>
        <Button kind="primary" onClick={handleCopy}>
          Copy link
        </Button>
      </div>
    </li>
  );
};

export const AccountSharing: React.FC<AccountSharingProps> = ({
  account,
  links,
  onCreate,
}) => (
  <div className="account-sharing__container">
    <div className="account-sharing__header">
      <h2 className="h3">{account.name} account sharing</h2>
      <LinkButton
        small
        kind="tertiary"
        to={`/accounts/${account.key}`}
        icons={{ left: <Icons.BackIcon /> }}
      >
        Back to account
      </LinkButton>
    </div>
    <div className="account-sharing__content">
      <div className="account-sharing__invite --link">
        <span>Create a link to give access to anyone</span>
        <span>{/*... role ...*/}</span>
        <Button kind="primary" onClick={() => onCreate()}>
          Create link
        </Button>
      </div>

      <div className="account-sharing__links">
        <h3 className="header">Invite links</h3>

        {links.length === 0 ? (
          <p>
            <small>
              There are no active invitation links for this account. Click the
              button above to create one.
            </small>
          </p>
        ) : (
          <ul className="account-sharing__links-list">
            {links.map((ent) => (
              <AccountSharingRow key={ent.id} ent={ent} />
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
);
