var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var ProfileIcon = function () { return (_jsx("svg", __assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10.0008 1C7.28922 1 5.09148 3.10209 5.09148 5.69565V6.47826C5.09148 9.07183 7.28922 11.1739 10.0008 11.1739C12.7124 11.1739 14.9101 9.07183 14.9101 6.47826V5.69565C14.9101 3.10209 12.7124 1 10.0008 1ZM9.9992 13.5217C6.72141 13.5217 2.51577 15.2175 1.30562 16.7225C0.557767 17.653 1.26968 19 2.49619 19H17.5038C18.7303 19 19.4422 17.653 18.6944 16.7225C17.4842 15.2183 13.277 13.5217 9.9992 13.5217Z", fill: "black" }, void 0) }), void 0)); };
