import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, SelectField, InputField, Icons } from "pokko-shared";
import { CreateProjectInput, Region } from "../ProjectCreate";
import { strings } from "strings";

export type CreateProjectTemplatedInput = CreateProjectInput & {
  template: string;
};

enum Platform {
  Vercel = "VERCEL",
  Netlify = "NETLIFY",
  Github = "GITHUB",
}

type Template = {
  id: string;
  name: string;
  description: string;
  integrations: Platform[];
};

export type ProjectCreateTemplatedStatusProps = {
  id: string;
  message: string;
  projectKey: string;
};

export type ProjectCreateTemplatedProps = {
  list: Template[];
  loading: boolean;
  status?: ProjectCreateTemplatedStatusProps;
  onSubmit: (values: CreateProjectTemplatedInput) => void;
};

export const ProjectCreateTemplatedStatus: React.FC<ProjectCreateTemplatedStatusProps> = ({
  projectKey,
  message,
}) => {
  if (projectKey) {
    return (
      <p>
        Project created <Link to={`../${projectKey}`}>go to project</Link>
      </p>
    );
  }

  return <pre>Status: {message}</pre>;
};

export const ProjectCreateTemplated: React.FC<
  ProjectCreateTemplatedProps & { regions: Region[]; onBack: () => void }
> = ({ onSubmit, regions, list, loading, status, onBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm<CreateProjectTemplatedInput>({
    defaultValues: {
      region: "au-syd1",
    },
  });
  const { pathname } = useLocation();
  // const { session } = useUser();
  const [session] = React.useState<any>({});

  if (status) {
    return <ProjectCreateTemplatedStatus {...status} />;
  }

  const selected = list.find((ent) => ent.id === watch("template"));
  const values = getValues();
  const state = encodeURI(btoa(JSON.stringify({ values, pathname })));

  const githubUrl = `https://github.com/login/oauth/authorize?client_id=7e25acba88323a34dba4&scope=repo&state=${state}`;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Button
          small
          kind="tertiary"
          icons={{ left: <Icons.BackIcon /> }}
          onClick={onBack}
        >
          Back
        </Button>
      </div>
      <span className="header">Bootstrap from a template</span>
      <p>
        Need a little inspiration? Use one of our templates to get the ball
        rolling.
      </p>

      <SelectField
        label="Region"
        error={errors.region}
        {...register("region", { required: strings.required })}
      >
        {regions.map((ent) => (
          <option key={ent.id} value={ent.id}>
            {ent.name} ({ent.id})
          </option>
        ))}
      </SelectField>
      <InputField
        label="Name"
        error={errors.name}
        {...register("name", { required: strings.required })}
      />
      <SelectField
        label="Template"
        error={errors.template}
        {...register("template", { required: strings.required })}
      >
        <option value="">Select...</option>
        <optgroup label="Official">
          {list.map((ent) => (
            <option key={ent.id} value={ent.id}>
              {ent.name}
            </option>
          ))}
        </optgroup>
      </SelectField>
      {selected ? <p>{selected.description}</p> : null}
      {selected?.integrations?.includes(Platform.Github) ? (
        session.github?.accessToken ? (
          <p>You've connected your Github account</p>
        ) : (
          <a href={githubUrl}>Connect Github account</a>
        )
      ) : null}
      <Button kind="primary" type="submit" loading={loading}>
        Create
      </Button>
      <p>
        <small>
          These include a data structure, some example content and media. Some
          include hooks into external services like{" "}
          <a
            href="https://vercel.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Vercel
          </a>{" "}
          to hook up full end-to-end solutions.
        </small>
      </p>
    </form>
  );
};
