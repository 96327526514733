import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Icons } from "pokko-shared";
import { useAuth } from "providers/auth";
import { useUser } from "providers/user";

export type SimpleNavbarProps = {
  actions?: React.ReactNode[];
};

export const SimpleNavbar: React.FC<SimpleNavbarProps> = ({ actions }) => {
  const {
    logout: { logout, status },
  } = useAuth();
  const { user } = useUser();

  return (
    <nav className="navbar-simple">
      <Link to="/" className="navbar-simple__logo">
        <Icons.PokkoType />
      </Link>
      <div className="navbar-simple__actions">
        {actions
          ? actions.map((ent, idx) => (
              <React.Fragment key={idx}>{ent}</React.Fragment>
            ))
          : null}
        {user ? (
          <Button onClick={logout} loading={status.loading}>
            Logout
          </Button>
        ) : null}
      </div>
    </nav>
  );
};
