import * as React from "react";
import { Button } from "pokko-shared";
import { strings } from "strings";
import { ProjectSettingsProps } from "../ProjectSettings";

export const DangerZone: React.FC<ProjectSettingsProps> = ({
  archived,
  archive: [archive, archiveStatus],
}) => {
  const [clicked, setClicked] = React.useState(false);

  const handleClick = async () => {
    await archive();

    setClicked(false);
  };

  return (
    <div className="project-settings__tokens">
      <div className="project-settings__tokens-header">
        <h4 className="header">Danger zone</h4>
      </div>
      {archived ? (
        <p>This project is currently archived.</p>
      ) : (
        <p>Mark this project as archived and read-only.</p>
      )}

      <div className="project-settings__tokens-actions">
        {clicked ? (
          <>
            <Button
              key="confirm"
              type="button"
              onClick={handleClick}
              loading={archiveStatus.loading}
              kind="primary"
            >
              {strings.confirm}
            </Button>
            <Button
              key="cancel"
              type="button"
              onClick={() => setClicked(false)}
              disabled={archiveStatus.loading}
              kind="tertiary"
            >
              {strings.cancel}
            </Button>
          </>
        ) : (
          <Button
            key="archive"
            type="button"
            onClick={() => setClicked(true)}
            kind="secondary"
          >
            {archived ? "Restore project" : "Archive project"}
          </Button>
        )}
      </div>
    </div>
  );
};
