import * as React from "react";
import { LinkButton, Icons } from "pokko-shared";
import { Rename } from "./components/Rename";
import { DangerZone } from "./components/DangerZone";

export type ProjectSettingsInput = {
  name: string;
};

export type ProjectSettingsProps = {
  name: string;
  value: ProjectSettingsInput;
  archived: boolean;

  save: [(value: ProjectSettingsInput) => void, { loading: boolean }];
  archive: [() => Promise<void>, { loading: boolean }];
};

export const ProjectSettings: React.FC<ProjectSettingsProps> = (props) => {
  const { name } = props;

  return (
    <div className="project-settings__container">
      <div className="project-settings__header">
        <h3 className="h3">{name} settings</h3>
        <LinkButton
          small
          kind="tertiary"
          to="../../.."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to account
        </LinkButton>
      </div>
      <Rename {...props} />
      <hr />
      <DangerZone {...props} />
    </div>
  );
};
