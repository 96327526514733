import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import GoogleLogin from "react-google-login";
import { Button, InputField, Message, Icons } from "pokko-shared";

import { FormProps } from "../defaults";

export type RegisterInput = {
  name: string;
  email: string;
  password: string;
};

export type RegisterFormProps = FormProps<RegisterInput> & {
  googleClientId?: string;
  onSocial?: (provider: string, response: any) => void;
};

export const RegisterForm: React.FC<RegisterFormProps> = ({
  defaultValues,
  error,
  googleClientId,
  loading,
  onSocial,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<RegisterInput>({
    defaultValues,
  });

  React.useEffect(() => reset(), [reset]);

  return (
    <div className="entry-form">
      <h2 className="h2 entry-form__heading">Get started today</h2>
      {googleClientId ? (
        <div className="entry-form__social">
          <GoogleLogin
            clientId={googleClientId}
            onSuccess={(res) => onSocial && onSocial("google", res)}
            onFailure={console.warn}
            cookiePolicy={"single_host_origin"}
            render={(props) => (
              <Button
                kind="tertiary"
                loading={props.disabled || loading}
                disabled={loading}
                onClick={props.onClick}
                icons={{
                  left: <Icons.GoogleIcon />,
                }}
              >
                Log in with Google
              </Button>
            )}
          />
        </div>
      ) : null}

      <div className="entry-form__separator">
        <p>Or create an account with your email</p>
      </div>

      <form className="entry-form__input" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          placeholder="Name"
          disabled={loading}
          error={errors.name}
          autoFocus
          {...register("name", {
            required: "This is a required field",
          })}
        />

        <InputField
          type="email"
          placeholder="Email"
          disabled={loading}
          error={errors.email}
          {...register("email", {
            required: "This is a required field",
            pattern: {
              value: /[^@]+@[^.]+\..+/,
              message: "The value you have entered is invalid",
            },
          })}
        />

        <InputField
          type="password"
          placeholder="Password"
          disabled={loading}
          error={errors.password}
          {...register("password", {
            required: "This is a required field",
            minLength: {
              value: 6,
              message: "Passwords must be at least six characters long",
            },
          })}
        />

        {error ? (
          <div className="entry-form__message">
            <Message size="small" colour="danger">
              <p>{error}</p>
            </Message>
          </div>
        ) : null}

        <Button kind="primary" loading={loading} disabled={loading}>
          Create account
        </Button>
      </form>

      <div className="entry-form__footer">
        <small>
          Have an account? <Link to="/login">Log in</Link>
        </small>
      </div>
    </div>
  );
};
