var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var EditIcon = function () { return (_jsxs("svg", __assign({ className: "icon__edit", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M3.58334 15.8333C3.42863 15.8333 3.28025 15.8948 3.17086 16.0042C3.06146 16.1136 3 16.2619 3 16.4167C3 16.5714 3.06146 16.7197 3.17086 16.8291C3.28025 16.9385 3.42863 17 3.58334 17C3.73805 17 3.88643 16.9385 3.99582 16.8291C4.10522 16.7197 4.16668 16.5714 4.16668 16.4167C4.16668 16.2619 4.10522 16.1136 3.99582 16.0042C3.88643 15.8948 3.73805 15.8333 3.58334 15.8333ZM15.2501 7.66657L16.6583 6.25839C17.1139 5.8028 17.1139 5.06429 16.6583 4.6087L15.3913 3.34169C14.9357 2.8861 14.1972 2.8861 13.7416 3.34169L12.3334 4.74987L15.2501 7.66657ZM11.1668 5.91655L4.31835 12.765C4.31835 12.765 4.85385 12.7171 5.05335 12.9166C5.25286 13.1161 5.08836 14.4216 5.33336 14.6666C5.57836 14.9116 6.87571 14.739 7.06179 14.9251C7.24788 15.1111 7.23504 15.6817 7.23504 15.6817L14.0834 8.83325L11.1668 5.91655Z", fill: "black" }, void 0), _jsx("path", { d: "M4.23935 15.7606L3.4787 15L3 16.355L3.2074 16.7926L4.23935 15.7606ZM4.23935 15.7606L5 16.5213L3.64503 17L3.2074 16.7926L4.23935 15.7606Z", fill: "black" }, void 0)] }), void 0)); };
