import * as React from "react";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { FormProps } from "../defaults";
import { Button, InputField, Message, Icons } from "pokko-shared";

export type LocalInput = {
  email: string;
  password: string;
};

export type LoginFormProps = FormProps<LocalInput> & {
  googleClientId?: string;
  onSocial?: (provider: string, response: any) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  defaultValues,
  error,
  googleClientId,
  loading,
  onSocial,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<LocalInput>({
    defaultValues,
  });

  React.useEffect(() => reset(), [reset]);

  return (
    <div className="entry-form">
      <h2 className="h2 entry-form__heading">Log in to my account</h2>
      {googleClientId ? (
        <div className="entry-form__social">
          <GoogleLogin
            clientId={googleClientId}
            onSuccess={(res) => onSocial && onSocial("google", res)}
            onFailure={console.warn}
            cookiePolicy={"single_host_origin"}
            render={(props) => (
              <Button
                kind="tertiary"
                loading={props.disabled || loading}
                disabled={loading}
                onClick={props.onClick}
                icons={{
                  left: <Icons.GoogleIcon />,
                }}
              >
                Log in with Google
              </Button>
            )}
          />
        </div>
      ) : null}

      <div className="entry-form__separator">
        <p>Or log in with your email</p>
      </div>

      <form className="entry-form__input" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="email"
          placeholder="Email"
          disabled={loading}
          error={errors.email}
          autoFocus
          {...register("email", {
            required: "This is a required field",
            pattern: {
              value: /[^@]+@[^.]+\..+/,
              message: "The value you have entered is invalid",
            },
          })}
        />

        <InputField
          type="password"
          placeholder="Password"
          disabled={loading}
          error={errors.password}
          {...register("password", {
            required: "This is a required field",
          })}
        />

        <div className="entry-form__forgot-password">
          <small>
            <Link to="/reset">Forgot password</Link>
          </small>
        </div>

        {error ? (
          <div className="entry-form__message">
            <Message size="small" colour="danger">
              <p>{error}</p>
            </Message>
          </div>
        ) : null}

        <Button
          kind="primary"
          loading={loading}
          disabled={loading}
          type="submit"
        >
          Log in
        </Button>
      </form>

      <div className="entry-form__footer">
        <small>
          Don't have an account? <Link to="/register">Create an account</Link>
        </small>
        <small>
          <Link to="/sso">Log in with SSO</Link>
        </small>
      </div>
    </div>
  );
};
