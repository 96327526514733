import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, SelectField, InputField, Icons } from "pokko-shared";
import { strings } from "strings";
import { CreateProjectInput, Region } from "../ProjectCreate";

export type ProjectCreateEmptyProps = {
  loading: boolean;

  onSubmit: (values: CreateProjectInput) => void;
};

export const ProjectCreateEmpty: React.FC<
  ProjectCreateEmptyProps & { regions: Region[]; onBack: () => void }
> = ({ onSubmit, loading, regions, onBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProjectInput>({
    defaultValues: { region: "au-syd1" },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Button
          small
          kind="tertiary"
          icons={{ left: <Icons.BackIcon /> }}
          onClick={onBack}
        >
          Back
        </Button>
      </div>
      <span className="header">Start fresh</span>
      <p>
        Start with a fresh slate, no models, no entries, no media. nothing. The
        world's your oyster.
      </p>
      <SelectField
        label="Region"
        error={errors.region}
        {...register("region", { required: strings.required })}
      >
        {regions.map((ent) => (
          <option key={ent.id} value={ent.id}>
            {ent.name} ({ent.id})
          </option>
        ))}
      </SelectField>
      <InputField
        label="Name"
        error={errors.name}
        {...register("name", { required: strings.required })}
      />
      <Button kind="primary" type="submit" loading={loading}>
        Create
      </Button>
    </form>
  );
};
