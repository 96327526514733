import * as React from "react";
import { Helmet } from "react-helmet";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { AccountSharing } from "components/accounts/sharing/AccountSharing";
import { useAccount } from "..";
import {
  useCreateAccountInviteMutation,
  useListAccountInvitesQuery,
  useRemoveAccountInviteMutation,
} from "api/graphql";

export const AccountSharingPage: React.FC = () => {
  const { account } = useAccount();

  const query = useListAccountInvitesQuery({
    variables: { key: account.key! },
  });
  const [create] = useCreateAccountInviteMutation();
  const [remove] = useRemoveAccountInviteMutation();

  const handleRemove = async (id: string) => {
    await remove({
      variables: {
        invite: id,
        account: account.id,
      },
    });

    query.refetch();
  };

  const handleCreate = async () => {
    await create({
      variables: {
        account: account.id!,
      },
    });

    query.refetch();
  };

  return (
    <SimpleLayout>
      <Helmet title={account.name!} />
      <AccountSharing
        account={{
          key: account.key,
          name: account.name,
        }}
        onCreate={handleCreate}
        links={
          query.data?.account.invites?.map((ent) => ({
            id: ent?.id!,
            url: `${window.location.origin}/invite?token=${ent?.token!}`,
            created: new Date(ent.createdAt + "Z"),
            onRemove: () => handleRemove(ent?.id!),
            role: "...", // ent?.role!,
          })) ?? []
        }
      />
    </SimpleLayout>
  );
};
