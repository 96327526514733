export const strings = {
  activate: "Activate",
  cancel: "Cancel",
  clear: "Clear",
  confirm: "Confirm",
  create: "Create",
  delete: "Delete",
  discard: "Discard changes",
  errorGeneric: "Something went wrong",
  invalid: "This field contains an invalid value",
  publish: "Publish",
  required: "This field is required",
  restore: "Restore",
  reset: "Reset",
  save: "Save changes",
  unpublish: "Retract",
  unsavedChanges: "You have unsaved changes. Are you sure?",
  account: {
    create: "Create account",
    onlyOneFree: (name: string) => `You already belong to a ${name} account`,
  },
};
