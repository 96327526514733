import * as React from "react";
import { Account } from "api/graphql";
import { Link } from "react-router-dom";
import { LinkButton, Icons } from "pokko-shared";

export type AccountListProps = {
  loading: boolean;
  accounts: Account[];
};

const Empty: React.FC = () => (
  <div className="accounts__empty">
    <Icons.EmptyIcon />
    <p>Looks like you’re low on accounts, to get started just create one.</p>
  </div>
);

export const AccountList: React.FC<AccountListProps> = ({
  loading,
  accounts,
}) => {
  if (loading) {
    return null;
  }

  return (
    <div className="accounts">
      <div className="accounts__header">
        <h2 className="h3">All accounts</h2>
        <LinkButton kind="primary" to="/accounts/create">
          Create account
        </LinkButton>
      </div>
      {accounts.length === 0 ? (
        <Empty />
      ) : (
        accounts.map((acc) => (
          <div key={acc.key} className="accounts__item">
            <div className="accounts__item-header">
              <h3 className="h3">{acc.name}</h3>
              <small>{/*strings.account.roles[acc.role]*/}</small>
              <LinkButton to={`/accounts/${acc.key}`} kind="tertiary">
                Manage account
              </LinkButton>
            </div>
            <div className="accounts__item-content">
              {acc.projects.filter((prj) => !prj.deletedAt).length === 0 ? (
                <p>
                  There are no projects in this account. To start, create a new
                  project.
                </p>
              ) : (
                <div>
                  <h4 className="header is-light">Projects</h4>
                  <ul>
                    {acc.projects
                      .filter((prj) => !prj.deletedAt)
                      .map((prj) => (
                        <li key={prj.key}>
                          <a href={prj.url}>
                            <strong>{prj.name}</strong>
                          </a>
                          <small
                            title={`This project is hosted in the ${prj.region.name} region.`}
                          >
                            {prj.region.name}
                          </small>
                          <Link to={`${acc.key}/projects/${prj.key}/settings`}>
                            <Icons.SettingsIcon />
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              <hr />
              <LinkButton
                kind="secondary"
                to={`/accounts/${acc.key}/projects/create`}
              >
                Create new project
              </LinkButton>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
