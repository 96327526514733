import * as React from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useUser } from "./user";
import { useAuth } from "./auth";

export const ApiProvider: React.FC = ({ children }) => {
  const { user } = useUser();
  const { logout } = useAuth();

  const headers = user
    ? {
        authorization: `Bearer ${user.token}`,
      }
    : {};

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.some((err) => err.message.includes("Unauthenticated"))) {
      logout.logout();
    }
  });

  const httpLink = new HttpLink({ uri: "/graphql", headers });

  const link = from([errorLink, httpLink]);

  const cache = new InMemoryCache();
  const client = new ApolloClient<any>({
    link,
    cache,
    connectToDevTools: true,
  });

  return React.createElement(ApolloProvider, { client, children });
};

export const NoAuthApiProvider: React.FC = ({ children }) => {
  const link = new HttpLink({ uri: "/graphql" });

  const cache = new InMemoryCache();
  const client = new ApolloClient<any>({
    link,
    cache,
    connectToDevTools: true,
  });

  return React.createElement(ApolloProvider, { client, children });
};
