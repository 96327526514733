import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Field, Input, Label, Message } from "pokko-shared";
import { strings } from "strings";
import { AccountSettingsInput, AccountSettingsProps } from "../AccountSettings";

export const Rename: React.FC<AccountSettingsProps> = ({
  value,
  save: [onSave, saveStatus],
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountSettingsInput>({
    defaultValues: value,
  });

  return (
    <form className="account-settings__name" onSubmit={handleSubmit(onSave)}>
      <Field>
        <Label>Account name</Label>
        <div className="account-settings__field-inline">
          <Input
            {...register("name", { required: strings.required })}
            state={errors.name ? "danger" : undefined}
          />
          <Button kind="secondary" type="submit" loading={saveStatus.loading}>
            Save
          </Button>
        </div>
        {errors.name?.message ? (
          <Message size="small" colour="danger">
            {errors.name.message}
          </Message>
        ) : null}
      </Field>
    </form>
  );
};
