import * as React from "react";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { useAccount } from "..";
import { useGetAccountUsageQuery } from "api/graphql";

export const AccountUsagePage: React.FC = () => {
  const { account } = useAccount();

  const query = useGetAccountUsageQuery({
    variables: {
      id: account.id,
    },
  });

  return (
    <SimpleLayout>
      <ul>
        {query.data?.accountUsage.facets.map((ent) => (
          <li key={ent.id}>
            <strong>{ent.name}</strong>
            <p>{`Limit: ${ent.value} ${ent.units}`}</p>
            <p>{`Total usage: ${ent.totalUsage} (${(
              (ent.totalUsage / ent.value) *
              100
            ).toFixed(2)} %)`}</p>

            <p>Usage</p>
            <ul>
              {ent.usage.map((prj) => (
                <li key={prj.project.id}>
                  <p>{prj.project.name}</p>
                  <p>{`${prj.usage} ${ent.units} (${(
                    (prj.usage / ent.value) *
                    100
                  ).toFixed(2)} %)`}</p>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </SimpleLayout>
  );
};
