import * as React from "react";
import { Routes, Route, Navigate, RouteProps } from "react-router";

import { useUser } from "providers/user";
import { useAuth } from "providers/auth";

import { AcceptInvitePage } from "./accept";
import { Login } from "./login";
import { Register } from "./register";
import { Reset } from "./reset";
import { SsoLogin } from "./sso";
import { Home } from "./home";
import { AccountPage } from "./account";
import { AccountCreatePage } from "./account/create";
import { Components } from "./dev";

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { user } = useUser();

  if (user) {
    return <Route {...props} />;
  }

  return <Navigate to="/login" />;
};

const AuthRoute: React.FC<RouteProps> = (props) => {
  const { user } = useUser();

  if (!user) {
    return <Route {...props} />;
  }

  return <Navigate to="/accounts" />;
};

const Logout: React.FC = () => {
  const {
    logout: { logout },
  } = useAuth();

  React.useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export const AppRoutes: React.FC = () => (
  <Routes>
    <AuthRoute path="/login" element={<Login />} />
    <AuthRoute path="/register" element={<Register />} />
    <AuthRoute path="/sso" element={<SsoLogin />} />
    <AuthRoute path="/reset" element={<Reset />} />
    <AuthRoute path="/logout" element={<Logout />} />

    <ProtectedRoute path="/invite" element={<AcceptInvitePage />} />
    <ProtectedRoute path="/accounts" element={<Home />} />
    <ProtectedRoute path="/accounts/create" element={<AccountCreatePage />} />
    <ProtectedRoute path="accounts/:account/*" element={<AccountPage />} />

    <Route path="/dev" element={<Components />} />

    <Route element={<Navigate to="/accounts" />} />
  </Routes>
);
