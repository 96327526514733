import * as React from "react";
import { Helmet } from "react-helmet";

import { LinkButton, Icons } from "pokko-shared";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { AccountLanding } from "components/accounts/landing/AccountLanding";
import { useAccount } from "..";

export const AccountHome: React.FC = () => {
  const { account, loading } = useAccount();

  return (
    <SimpleLayout
      navbar={{
        actions: [
          <LinkButton
            small
            kind="tertiary"
            to=".."
            icons={{ left: <Icons.BackIcon /> }}
          >
            Back to all accounts
          </LinkButton>,
        ],
      }}
    >
      <Helmet title={account.name} />
      <AccountLanding loading={loading} account={account} />
    </SimpleLayout>
  );
};
