import * as React from "react";
import { useCreateProjectMutation, useListRegionsQuery } from "api/graphql";
import { useAccount } from "routes/account";
// import { CreateProjectTemplatedInput } from "components/projects/create/components/CreateTemplated";
import {
  CreateProjectInput,
  ProjectCreate,
} from "components/projects/create/ProjectCreate";
import { ImportProjectInput } from "components/projects/create/components/CreateImport";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

export const ProjectCreatePage: React.FC = () => {
  const { account } = useAccount();
  const regionsQuery = useListRegionsQuery();

  // const [templateList, setTemplateList] = React.useState([]);

  const [createRequest, createRequestStatus] = useCreateProjectMutation();

  const handleCreateEmpty = async (values: CreateProjectInput) => {
    const res = await createRequest({
      variables: {
        input: {
          account: account.id,
          region: values.region,
          name: values.name,
          payload: {
            empty: true,
          },
        },
      },
    });

    if (res.data?.createProject?.url) {
      window.location.href = res.data?.createProject?.url;
    }
  };

  // const handleCreateTemplated = async (values: CreateProjectTemplatedInput) => {
  //   await createRequest({
  //     variables: {
  //       input: {
  //         account: account.id,
  //         region: values.region,
  //         name: values.name,
  //         payload: {
  //           template: values.template,
  //         },
  //       },
  //     },
  //   });
  // };

  const handleCreateImported = async (values: ImportProjectInput) => {
    const res = await createRequest({
      variables: {
        input: {
          account: account.id,
          region: values.region,
          name: values.name,
          payload: values.config,
        },
      },
    });

    if (res.data?.createProject?.url) {
      window.location.href = res.data?.createProject?.url;
    }
  };

  // React.useEffect(() => {
  //   fetch(
  //     "https://raw.githubusercontent.com/pokkocms/starters/main/manifest.json"
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setTemplateList(res.list);
  //     });
  // }, []);

  return (
    <SimpleLayout>
      <ProjectCreate
        regions={regionsQuery.data?.regions ?? []}
        empty={{
          loading: createRequestStatus.loading,
          onSubmit: handleCreateEmpty,
        }}
        // templated={{
        //   loading: createRequestStatus.loading,
        //   list: templateList,
        //   onSubmit: handleCreateTemplated,
        // }}
        imported={{
          loading: createRequestStatus.loading,
          onSubmit: handleCreateImported,
        }}
      />
    </SimpleLayout>
  );
};
