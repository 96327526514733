import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf) */
  JSON: any;
};

export type Project = {
  __typename?: 'Project';
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  region: Region;
  url: Scalars['String'];
};

export type ProjectPatch = {
  name?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  token?: Maybe<Scalars['String']>;
};

export type AccountUsage = {
  __typename?: 'AccountUsage';
  facets: Array<AccountUsageFacet>;
};

export type ProjectFacetUsage = {
  __typename?: 'ProjectFacetUsage';
  project: Project;
  usage: Scalars['Int'];
};


export type Query = {
  __typename?: 'Query';
  account: Account;
  accountInvite: PublicAccountInvite;
  accountUsage: AccountUsage;
  accounts: Array<Account>;
  project: Project;
  regions: Array<Region>;
  saml?: Maybe<SamlResult>;
};


export type QueryAccountArgs = {
  key: Scalars['String'];
};


export type QueryAccountInviteArgs = {
  token: Scalars['String'];
};


export type QueryAccountUsageArgs = {
  id: Scalars['String'];
};


export type QueryProjectArgs = {
  key: Scalars['String'];
  account: Scalars['String'];
};


export type QuerySamlArgs = {
  email: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AccountPatch = {
  name: Scalars['String'];
};

export type CreateProjectInput = {
  payload?: Maybe<Scalars['JSON']>;
  account: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  invites: Array<AccountInvite>;
  key: Scalars['String'];
  name: Scalars['String'];
  projects: Array<Project>;
  users: Array<AccountUser>;
};

export type AccountUser = {
  __typename?: 'AccountUser';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SamlResult = {
  __typename?: 'SamlResult';
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAccountInvite?: Maybe<Account>;
  createAccount?: Maybe<Account>;
  createAccountInvite?: Maybe<Array<Maybe<AccountInvite>>>;
  createProject?: Maybe<Project>;
  local?: Maybe<LoginResult>;
  logout?: Maybe<Scalars['Boolean']>;
  passwordReset?: Maybe<LoginResult>;
  passwordResetRequest?: Maybe<Scalars['Boolean']>;
  register?: Maybe<LoginResult>;
  removeAccountInvite?: Maybe<Array<Maybe<AccountInvite>>>;
  removeAccountUser: Account;
  social?: Maybe<LoginResult>;
  updateAccount?: Maybe<Account>;
  updateProject?: Maybe<Project>;
};


export type MutationAcceptAccountInviteArgs = {
  token: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateAccountInviteArgs = {
  account: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationLocalArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLogoutArgs = {
  token: Scalars['String'];
};


export type MutationPasswordResetArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationPasswordResetRequestArgs = {
  email: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRemoveAccountInviteArgs = {
  account: Scalars['String'];
  invite: Scalars['String'];
};


export type MutationRemoveAccountUserArgs = {
  account: Scalars['String'];
  user: Scalars['String'];
};


export type MutationSocialArgs = {
  provider: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['String'];
  patch: AccountPatch;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['String'];
  patch: ProjectPatch;
};

export type AccountInvite = {
  __typename?: 'AccountInvite';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  token: Scalars['String'];
};

export type AccountUsageFacet = {
  __typename?: 'AccountUsageFacet';
  id: Scalars['String'];
  name: Scalars['String'];
  totalUsage: Scalars['Int'];
  units: Scalars['String'];
  usage: Array<ProjectFacetUsage>;
  value: Scalars['Int'];
};

export type PublicAccountInvite = {
  __typename?: 'PublicAccountInvite';
  existing: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateAccountInput = {
  name: Scalars['String'];
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
  )> }
);

export type ListAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'key' | 'name' | 'url' | 'deletedAt'>
      & { region: (
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetAccountQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'key' | 'name' | 'url' | 'deletedAt'>
      & { region: (
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String'];
  patch: AccountPatch;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
  )> }
);

export type ListAccountUsersQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ListAccountUsersQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & { users: Array<(
      { __typename?: 'AccountUser' }
      & Pick<AccountUser, 'id' | 'email' | 'name'>
    )> }
  ) }
);

export type RemoveAccountUserMutationVariables = Exact<{
  user: Scalars['String'];
  account: Scalars['String'];
}>;


export type RemoveAccountUserMutation = (
  { __typename?: 'Mutation' }
  & { removeAccountUser: (
    { __typename?: 'Account' }
    & { users: Array<(
      { __typename?: 'AccountUser' }
      & Pick<AccountUser, 'id' | 'email' | 'name'>
    )> }
  ) }
);

export type ListAccountInvitesQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ListAccountInvitesQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & { invites: Array<(
      { __typename?: 'AccountInvite' }
      & Pick<AccountInvite, 'id' | 'token' | 'createdAt'>
    )> }
  ) }
);

export type RemoveAccountInviteMutationVariables = Exact<{
  account: Scalars['String'];
  invite: Scalars['String'];
}>;


export type RemoveAccountInviteMutation = (
  { __typename?: 'Mutation' }
  & { removeAccountInvite?: Maybe<Array<Maybe<(
    { __typename?: 'AccountInvite' }
    & Pick<AccountInvite, 'id' | 'token'>
  )>>> }
);

export type CreateAccountInviteMutationVariables = Exact<{
  account: Scalars['String'];
}>;


export type CreateAccountInviteMutation = (
  { __typename?: 'Mutation' }
  & { createAccountInvite?: Maybe<Array<Maybe<(
    { __typename?: 'AccountInvite' }
    & Pick<AccountInvite, 'id' | 'token'>
  )>>> }
);

export type GetAccountInviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetAccountInviteQuery = (
  { __typename?: 'Query' }
  & { accountInvite: (
    { __typename?: 'PublicAccountInvite' }
    & Pick<PublicAccountInvite, 'name' | 'key' | 'existing'>
  ) }
);

export type AcceptAccountInviteMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AcceptAccountInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptAccountInvite?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
  )> }
);

export type GetAccountUsageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAccountUsageQuery = (
  { __typename?: 'Query' }
  & { accountUsage: (
    { __typename?: 'AccountUsage' }
    & { facets: Array<(
      { __typename?: 'AccountUsageFacet' }
      & Pick<AccountUsageFacet, 'id' | 'name' | 'units' | 'totalUsage' | 'value'>
      & { usage: Array<(
        { __typename?: 'ProjectFacetUsage' }
        & Pick<ProjectFacetUsage, 'usage'>
        & { project: (
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'key' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type RegisterLocalMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterLocalMutation = (
  { __typename?: 'Mutation' }
  & { register?: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
  )> }
);

export type LoginLocalMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginLocalMutation = (
  { __typename?: 'Mutation' }
  & { local?: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
  )> }
);

export type LoginSocialMutationVariables = Exact<{
  provider: Scalars['String'];
  token: Scalars['String'];
}>;


export type LoginSocialMutation = (
  { __typename?: 'Mutation' }
  & { social?: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
  )> }
);

export type SamlUrlQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type SamlUrlQuery = (
  { __typename?: 'Query' }
  & { saml?: Maybe<(
    { __typename?: 'SamlResult' }
    & Pick<SamlResult, 'url'>
  )> }
);

export type LogoutMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'url'>
  )> }
);

export type ListRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListRegionsQuery = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  )> }
);

export type GetProjectQueryVariables = Exact<{
  key: Scalars['String'];
  account: Scalars['String'];
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'key' | 'name' | 'url' | 'deletedAt'>
    & { region: (
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['String'];
  patch: ProjectPatch;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'key' | 'name'>
  )> }
);

export type PasswordResetRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordResetRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'passwordResetRequest'>
);

export type PasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset?: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
  )> }
);


export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    id
    key
    name
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const ListAccountsDocument = gql`
    query ListAccounts {
  accounts {
    id
    key
    name
    projects {
      id
      key
      name
      url
      deletedAt
      region {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListAccountsQuery__
 *
 * To run a query within a React component, call `useListAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>) {
        return Apollo.useQuery<ListAccountsQuery, ListAccountsQueryVariables>(ListAccountsDocument, baseOptions);
      }
export function useListAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>) {
          return Apollo.useLazyQuery<ListAccountsQuery, ListAccountsQueryVariables>(ListAccountsDocument, baseOptions);
        }
export type ListAccountsQueryHookResult = ReturnType<typeof useListAccountsQuery>;
export type ListAccountsLazyQueryHookResult = ReturnType<typeof useListAccountsLazyQuery>;
export type ListAccountsQueryResult = Apollo.QueryResult<ListAccountsQuery, ListAccountsQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($key: String!) {
  account(key: $key) {
    id
    key
    name
    projects {
      id
      key
      name
      url
      deletedAt
      region {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: String!, $patch: AccountPatch!) {
  updateAccount(id: $id, patch: $patch) {
    id
    key
    name
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const ListAccountUsersDocument = gql`
    query ListAccountUsers($key: String!) {
  account(key: $key) {
    users {
      id
      email
      name
    }
  }
}
    `;

/**
 * __useListAccountUsersQuery__
 *
 * To run a query within a React component, call `useListAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountUsersQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useListAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAccountUsersQuery, ListAccountUsersQueryVariables>) {
        return Apollo.useQuery<ListAccountUsersQuery, ListAccountUsersQueryVariables>(ListAccountUsersDocument, baseOptions);
      }
export function useListAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountUsersQuery, ListAccountUsersQueryVariables>) {
          return Apollo.useLazyQuery<ListAccountUsersQuery, ListAccountUsersQueryVariables>(ListAccountUsersDocument, baseOptions);
        }
export type ListAccountUsersQueryHookResult = ReturnType<typeof useListAccountUsersQuery>;
export type ListAccountUsersLazyQueryHookResult = ReturnType<typeof useListAccountUsersLazyQuery>;
export type ListAccountUsersQueryResult = Apollo.QueryResult<ListAccountUsersQuery, ListAccountUsersQueryVariables>;
export const RemoveAccountUserDocument = gql`
    mutation RemoveAccountUser($user: String!, $account: String!) {
  removeAccountUser(account: $account, user: $user) {
    users {
      id
      email
      name
    }
  }
}
    `;
export type RemoveAccountUserMutationFn = Apollo.MutationFunction<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>;

/**
 * __useRemoveAccountUserMutation__
 *
 * To run a mutation, you first call `useRemoveAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountUserMutation, { data, loading, error }] = useRemoveAccountUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRemoveAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>) {
        return Apollo.useMutation<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>(RemoveAccountUserDocument, baseOptions);
      }
export type RemoveAccountUserMutationHookResult = ReturnType<typeof useRemoveAccountUserMutation>;
export type RemoveAccountUserMutationResult = Apollo.MutationResult<RemoveAccountUserMutation>;
export type RemoveAccountUserMutationOptions = Apollo.BaseMutationOptions<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>;
export const ListAccountInvitesDocument = gql`
    query ListAccountInvites($key: String!) {
  account(key: $key) {
    invites {
      id
      token
      createdAt
    }
  }
}
    `;

/**
 * __useListAccountInvitesQuery__
 *
 * To run a query within a React component, call `useListAccountInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountInvitesQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useListAccountInvitesQuery(baseOptions: Apollo.QueryHookOptions<ListAccountInvitesQuery, ListAccountInvitesQueryVariables>) {
        return Apollo.useQuery<ListAccountInvitesQuery, ListAccountInvitesQueryVariables>(ListAccountInvitesDocument, baseOptions);
      }
export function useListAccountInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountInvitesQuery, ListAccountInvitesQueryVariables>) {
          return Apollo.useLazyQuery<ListAccountInvitesQuery, ListAccountInvitesQueryVariables>(ListAccountInvitesDocument, baseOptions);
        }
export type ListAccountInvitesQueryHookResult = ReturnType<typeof useListAccountInvitesQuery>;
export type ListAccountInvitesLazyQueryHookResult = ReturnType<typeof useListAccountInvitesLazyQuery>;
export type ListAccountInvitesQueryResult = Apollo.QueryResult<ListAccountInvitesQuery, ListAccountInvitesQueryVariables>;
export const RemoveAccountInviteDocument = gql`
    mutation RemoveAccountInvite($account: String!, $invite: String!) {
  removeAccountInvite(account: $account, invite: $invite) {
    id
    token
  }
}
    `;
export type RemoveAccountInviteMutationFn = Apollo.MutationFunction<RemoveAccountInviteMutation, RemoveAccountInviteMutationVariables>;

/**
 * __useRemoveAccountInviteMutation__
 *
 * To run a mutation, you first call `useRemoveAccountInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountInviteMutation, { data, loading, error }] = useRemoveAccountInviteMutation({
 *   variables: {
 *      account: // value for 'account'
 *      invite: // value for 'invite'
 *   },
 * });
 */
export function useRemoveAccountInviteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountInviteMutation, RemoveAccountInviteMutationVariables>) {
        return Apollo.useMutation<RemoveAccountInviteMutation, RemoveAccountInviteMutationVariables>(RemoveAccountInviteDocument, baseOptions);
      }
export type RemoveAccountInviteMutationHookResult = ReturnType<typeof useRemoveAccountInviteMutation>;
export type RemoveAccountInviteMutationResult = Apollo.MutationResult<RemoveAccountInviteMutation>;
export type RemoveAccountInviteMutationOptions = Apollo.BaseMutationOptions<RemoveAccountInviteMutation, RemoveAccountInviteMutationVariables>;
export const CreateAccountInviteDocument = gql`
    mutation CreateAccountInvite($account: String!) {
  createAccountInvite(account: $account) {
    id
    token
  }
}
    `;
export type CreateAccountInviteMutationFn = Apollo.MutationFunction<CreateAccountInviteMutation, CreateAccountInviteMutationVariables>;

/**
 * __useCreateAccountInviteMutation__
 *
 * To run a mutation, you first call `useCreateAccountInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountInviteMutation, { data, loading, error }] = useCreateAccountInviteMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useCreateAccountInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountInviteMutation, CreateAccountInviteMutationVariables>) {
        return Apollo.useMutation<CreateAccountInviteMutation, CreateAccountInviteMutationVariables>(CreateAccountInviteDocument, baseOptions);
      }
export type CreateAccountInviteMutationHookResult = ReturnType<typeof useCreateAccountInviteMutation>;
export type CreateAccountInviteMutationResult = Apollo.MutationResult<CreateAccountInviteMutation>;
export type CreateAccountInviteMutationOptions = Apollo.BaseMutationOptions<CreateAccountInviteMutation, CreateAccountInviteMutationVariables>;
export const GetAccountInviteDocument = gql`
    query GetAccountInvite($token: String!) {
  accountInvite(token: $token) {
    name
    key
    existing
  }
}
    `;

/**
 * __useGetAccountInviteQuery__
 *
 * To run a query within a React component, call `useGetAccountInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetAccountInviteQuery(baseOptions: Apollo.QueryHookOptions<GetAccountInviteQuery, GetAccountInviteQueryVariables>) {
        return Apollo.useQuery<GetAccountInviteQuery, GetAccountInviteQueryVariables>(GetAccountInviteDocument, baseOptions);
      }
export function useGetAccountInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountInviteQuery, GetAccountInviteQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountInviteQuery, GetAccountInviteQueryVariables>(GetAccountInviteDocument, baseOptions);
        }
export type GetAccountInviteQueryHookResult = ReturnType<typeof useGetAccountInviteQuery>;
export type GetAccountInviteLazyQueryHookResult = ReturnType<typeof useGetAccountInviteLazyQuery>;
export type GetAccountInviteQueryResult = Apollo.QueryResult<GetAccountInviteQuery, GetAccountInviteQueryVariables>;
export const AcceptAccountInviteDocument = gql`
    mutation AcceptAccountInvite($token: String!) {
  acceptAccountInvite(token: $token) {
    id
    key
    name
  }
}
    `;
export type AcceptAccountInviteMutationFn = Apollo.MutationFunction<AcceptAccountInviteMutation, AcceptAccountInviteMutationVariables>;

/**
 * __useAcceptAccountInviteMutation__
 *
 * To run a mutation, you first call `useAcceptAccountInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAccountInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAccountInviteMutation, { data, loading, error }] = useAcceptAccountInviteMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptAccountInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAccountInviteMutation, AcceptAccountInviteMutationVariables>) {
        return Apollo.useMutation<AcceptAccountInviteMutation, AcceptAccountInviteMutationVariables>(AcceptAccountInviteDocument, baseOptions);
      }
export type AcceptAccountInviteMutationHookResult = ReturnType<typeof useAcceptAccountInviteMutation>;
export type AcceptAccountInviteMutationResult = Apollo.MutationResult<AcceptAccountInviteMutation>;
export type AcceptAccountInviteMutationOptions = Apollo.BaseMutationOptions<AcceptAccountInviteMutation, AcceptAccountInviteMutationVariables>;
export const GetAccountUsageDocument = gql`
    query GetAccountUsage($id: String!) {
  accountUsage(id: $id) {
    facets {
      id
      name
      units
      totalUsage
      usage {
        project {
          id
          key
          name
        }
        usage
      }
      value
    }
  }
}
    `;

/**
 * __useGetAccountUsageQuery__
 *
 * To run a query within a React component, call `useGetAccountUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountUsageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountUsageQuery(baseOptions: Apollo.QueryHookOptions<GetAccountUsageQuery, GetAccountUsageQueryVariables>) {
        return Apollo.useQuery<GetAccountUsageQuery, GetAccountUsageQueryVariables>(GetAccountUsageDocument, baseOptions);
      }
export function useGetAccountUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountUsageQuery, GetAccountUsageQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountUsageQuery, GetAccountUsageQueryVariables>(GetAccountUsageDocument, baseOptions);
        }
export type GetAccountUsageQueryHookResult = ReturnType<typeof useGetAccountUsageQuery>;
export type GetAccountUsageLazyQueryHookResult = ReturnType<typeof useGetAccountUsageLazyQuery>;
export type GetAccountUsageQueryResult = Apollo.QueryResult<GetAccountUsageQuery, GetAccountUsageQueryVariables>;
export const RegisterLocalDocument = gql`
    mutation RegisterLocal($name: String!, $email: String!, $password: String!) {
  register(name: $name, email: $email, password: $password) {
    token
  }
}
    `;
export type RegisterLocalMutationFn = Apollo.MutationFunction<RegisterLocalMutation, RegisterLocalMutationVariables>;

/**
 * __useRegisterLocalMutation__
 *
 * To run a mutation, you first call `useRegisterLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLocalMutation, { data, loading, error }] = useRegisterLocalMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterLocalMutation(baseOptions?: Apollo.MutationHookOptions<RegisterLocalMutation, RegisterLocalMutationVariables>) {
        return Apollo.useMutation<RegisterLocalMutation, RegisterLocalMutationVariables>(RegisterLocalDocument, baseOptions);
      }
export type RegisterLocalMutationHookResult = ReturnType<typeof useRegisterLocalMutation>;
export type RegisterLocalMutationResult = Apollo.MutationResult<RegisterLocalMutation>;
export type RegisterLocalMutationOptions = Apollo.BaseMutationOptions<RegisterLocalMutation, RegisterLocalMutationVariables>;
export const LoginLocalDocument = gql`
    mutation LoginLocal($email: String!, $password: String!) {
  local(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginLocalMutationFn = Apollo.MutationFunction<LoginLocalMutation, LoginLocalMutationVariables>;

/**
 * __useLoginLocalMutation__
 *
 * To run a mutation, you first call `useLoginLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginLocalMutation, { data, loading, error }] = useLoginLocalMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginLocalMutation(baseOptions?: Apollo.MutationHookOptions<LoginLocalMutation, LoginLocalMutationVariables>) {
        return Apollo.useMutation<LoginLocalMutation, LoginLocalMutationVariables>(LoginLocalDocument, baseOptions);
      }
export type LoginLocalMutationHookResult = ReturnType<typeof useLoginLocalMutation>;
export type LoginLocalMutationResult = Apollo.MutationResult<LoginLocalMutation>;
export type LoginLocalMutationOptions = Apollo.BaseMutationOptions<LoginLocalMutation, LoginLocalMutationVariables>;
export const LoginSocialDocument = gql`
    mutation LoginSocial($provider: String!, $token: String!) {
  social(provider: $provider, token: $token) {
    token
  }
}
    `;
export type LoginSocialMutationFn = Apollo.MutationFunction<LoginSocialMutation, LoginSocialMutationVariables>;

/**
 * __useLoginSocialMutation__
 *
 * To run a mutation, you first call `useLoginSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSocialMutation, { data, loading, error }] = useLoginSocialMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginSocialMutation(baseOptions?: Apollo.MutationHookOptions<LoginSocialMutation, LoginSocialMutationVariables>) {
        return Apollo.useMutation<LoginSocialMutation, LoginSocialMutationVariables>(LoginSocialDocument, baseOptions);
      }
export type LoginSocialMutationHookResult = ReturnType<typeof useLoginSocialMutation>;
export type LoginSocialMutationResult = Apollo.MutationResult<LoginSocialMutation>;
export type LoginSocialMutationOptions = Apollo.BaseMutationOptions<LoginSocialMutation, LoginSocialMutationVariables>;
export const SamlUrlDocument = gql`
    query SamlUrl($email: String!) {
  saml(email: $email) {
    url
  }
}
    `;

/**
 * __useSamlUrlQuery__
 *
 * To run a query within a React component, call `useSamlUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamlUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamlUrlQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSamlUrlQuery(baseOptions: Apollo.QueryHookOptions<SamlUrlQuery, SamlUrlQueryVariables>) {
        return Apollo.useQuery<SamlUrlQuery, SamlUrlQueryVariables>(SamlUrlDocument, baseOptions);
      }
export function useSamlUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SamlUrlQuery, SamlUrlQueryVariables>) {
          return Apollo.useLazyQuery<SamlUrlQuery, SamlUrlQueryVariables>(SamlUrlDocument, baseOptions);
        }
export type SamlUrlQueryHookResult = ReturnType<typeof useSamlUrlQuery>;
export type SamlUrlLazyQueryHookResult = ReturnType<typeof useSamlUrlLazyQuery>;
export type SamlUrlQueryResult = Apollo.QueryResult<SamlUrlQuery, SamlUrlQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout($token: String!) {
  logout(token: $token)
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    id
    url
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ListRegionsDocument = gql`
    query ListRegions {
  regions {
    id
    name
  }
}
    `;

/**
 * __useListRegionsQuery__
 *
 * To run a query within a React component, call `useListRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRegionsQuery(baseOptions?: Apollo.QueryHookOptions<ListRegionsQuery, ListRegionsQueryVariables>) {
        return Apollo.useQuery<ListRegionsQuery, ListRegionsQueryVariables>(ListRegionsDocument, baseOptions);
      }
export function useListRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRegionsQuery, ListRegionsQueryVariables>) {
          return Apollo.useLazyQuery<ListRegionsQuery, ListRegionsQueryVariables>(ListRegionsDocument, baseOptions);
        }
export type ListRegionsQueryHookResult = ReturnType<typeof useListRegionsQuery>;
export type ListRegionsLazyQueryHookResult = ReturnType<typeof useListRegionsLazyQuery>;
export type ListRegionsQueryResult = Apollo.QueryResult<ListRegionsQuery, ListRegionsQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($key: String!, $account: String!) {
  project(key: $key, account: $account) {
    id
    key
    name
    region {
      id
      name
    }
    url
    deletedAt
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      key: // value for 'key'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: String!, $patch: ProjectPatch!) {
  updateProject(id: $id, patch: $patch) {
    id
    key
    name
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const PasswordResetRequestDocument = gql`
    mutation PasswordResetRequest($email: String!) {
  passwordResetRequest(email: $email)
}
    `;
export type PasswordResetRequestMutationFn = Apollo.MutationFunction<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;

/**
 * __usePasswordResetRequestMutation__
 *
 * To run a mutation, you first call `usePasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetRequestMutation, { data, loading, error }] = usePasswordResetRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetRequestMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>) {
        return Apollo.useMutation<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>(PasswordResetRequestDocument, baseOptions);
      }
export type PasswordResetRequestMutationHookResult = ReturnType<typeof usePasswordResetRequestMutation>;
export type PasswordResetRequestMutationResult = Apollo.MutationResult<PasswordResetRequestMutation>;
export type PasswordResetRequestMutationOptions = Apollo.BaseMutationOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($token: String!, $password: String!) {
  passwordReset(token: $token, password: $password) {
    token
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;