var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var DangerIcon = function () { return (_jsx("svg", __assign({ className: "icon__danger", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.8 10C16.8 13.7555 13.7555 16.8 10 16.8C6.24446 16.8 3.2 13.7555 3.2 10C3.2 6.24446 6.24446 3.2 10 3.2C13.7555 3.2 16.8 6.24446 16.8 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10.6 5.20002L10.6 11.6H9.4L9.4 5.20002H10.6ZM10.7999 14C10.7999 14.4418 10.4417 14.8 9.99987 14.8C9.55804 14.8 9.19987 14.4418 9.19987 14C9.19987 13.5582 9.55804 13.2 9.99987 13.2C10.4417 13.2 10.7999 13.5582 10.7999 14Z" }, void 0) }), void 0)); };
