import * as React from "react";

import {
  ResetStep1Input,
  PasswordResetStep1Form,
} from "components/entry/reset/PasswordResetStep1Form";
import { usePasswordResetRequestMutation } from "api/graphql";

export const ResetPasswordStep1: React.FC = () => {
  const [success, setSuccess] = React.useState(false);
  const [reset, resetStatus] = usePasswordResetRequestMutation();

  const handleSubmit = async ({ email }: ResetStep1Input) => {
    try {
      await reset({ variables: { email } });
      setSuccess(true);
    } catch (ex) {
      console.warn(ex);
    }
  };

  return (
    <PasswordResetStep1Form
      loading={resetStatus.loading}
      success={success}
      error={resetStatus.error?.message}
      onSubmit={handleSubmit}
    />
  );
};
