var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var DeleteIcon = function () { return (_jsx("svg", __assign({ className: "icon__delete", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M3 3.04545H17M7.11765 14.0909L12.8824 8.36364M12.8824 14.0909L7.11765 8.36364M7.52941 3.04545V1.81818C7.52941 1.36818 7.9 1 8.35294 1H11.6471C12.1 1 12.4706 1.36818 12.4706 1.81818V3.04545H7.52941ZM16 3.04545V18.1818C16 18.6318 15.6294 19 15.3529 19H4.82353C4.37059 19 4 18.6318 4 18.1818V3.04545H16Z", stroke: "#8D95A5", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0) }), void 0)); };
export var DeleteAltIcon = function () { return (_jsxs("svg", __assign({ className: "icon__delete-alt", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { className: "icon__delete-alt--hat", d: "M4 3.04545H16M7.52941 3.04545V1.81818C7.52941 1.36818 7.9 1 8.35294 1H11.6471C12.1 1 12.4706 1.36818 12.4706 1.81818V3.04545H7.52941Z", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0), _jsx("path", { className: "icon__delete-alt--body", d: "M16 18.1189V5H4V18.1189C4 18.6035 4.37059 19 4.82353 19H15.3529C15.6294 19 16 18.6035 16 18.1189Z", fill: "black" }, void 0)] }), void 0)); };
