import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { FormProps } from "../defaults";
import { InputField, Message, Button, MessageColour } from "pokko-shared";

export type SsoLoginInput = {
  email: string;
};

export type SsoLoginFormProps = FormProps<SsoLoginInput> & {
  message?: string;
  messageColour?: MessageColour;
};

export const SsoLoginForm: React.FC<SsoLoginFormProps> = ({
  defaultValues,
  error,
  message,
  messageColour,
  loading,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<SsoLoginInput>({
    defaultValues,
  });

  React.useEffect(() => reset(), [reset]);

  return (
    <div className="entry-form">
      <h2 className="h2 entry-form__heading">Welcome</h2>

      <form className="entry-form__input" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="email"
          placeholder="Work email"
          disabled={loading}
          error={errors.email}
          autoFocus
          {...register("email", {
            required: "This is a required field",
            pattern: {
              value: /[^@]+@[^.]+\..+/,
              message: "The value you have entered is invalid",
            },
          })}
        />

        {error ? (
          <div className="entry-form__message">
            <Message size="small" colour="danger">
              <p>{error}</p>
            </Message>
          </div>
        ) : null}

        {message ? (
          <div className="entry-form__message">
            <Message size="small" colour={messageColour}>
              <p>{message}</p>
            </Message>
          </div>
        ) : null}

        <Button kind="primary" loading={loading} type="submit">
          Log in with SSO
        </Button>
      </form>

      <div className="entry-form__footer">
        <small>
          Don't have an SSO? <Link to="/login">Log in with your email</Link>
        </small>
      </div>
    </div>
  );
};
