var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var GoogleIcon = function () { return (_jsxs("svg", __assign({ viewBox: "0 0 21 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0)" }, { children: [_jsx("path", { d: "M20.4999 11.3963C20.4999 10.7027 20.4436 10.0054 20.3237 9.32316H10.7004V13.2519H16.2112C15.9825 14.519 15.2478 15.6399 14.1719 16.3522V18.9014H17.4596C19.3902 17.1245 20.4999 14.5003 20.4999 11.3963Z", fill: "#4285F4" }, void 0), _jsx("path", { d: "M10.7006 21.3644C13.4522 21.3644 15.7728 20.4609 17.4635 18.9014L14.1757 16.3522C13.261 16.9745 12.0802 17.3269 10.7043 17.3269C8.04267 17.3269 5.78587 15.5312 4.97613 13.1169H1.58344V15.7449C3.31539 19.19 6.84304 21.3644 10.7006 21.3644Z", fill: "#34A853" }, void 0), _jsx("path", { d: "M4.97233 13.117C4.54497 11.8499 4.54497 10.4778 4.97233 9.21068V6.58275H1.58339C0.136346 9.4656 0.136346 12.862 1.58339 15.7449L4.97233 13.117Z", fill: "#FBBC04" }, void 0), _jsx("path", { d: "M10.7006 4.99697C12.1551 4.97448 13.5609 5.52181 14.6144 6.5265L17.5272 3.61366C15.6828 1.8817 13.2348 0.929496 10.7006 0.959487C6.84304 0.959487 3.31539 3.13381 1.58344 6.58273L4.97238 9.21066C5.77837 6.79266 8.03892 4.99697 10.7006 4.99697Z", fill: "#EA4335" }, void 0)] }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0" }, { children: _jsx("rect", { width: "20", height: "20.4049", fill: "white", transform: "translate(0.5 0.959522)" }, void 0) }), void 0) }, void 0)] }), void 0)); };
