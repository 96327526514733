var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var InviteIcon = function () { return (_jsxs("svg", __assign({ viewBox: "0 0 48 46", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M23.0292 28C22.3969 28 21.8024 27.7577 21.3553 27.3187L16 22.0604L18.2298 19.871L23.0292 24.5827L31.7702 16L34 18.1894L24.7031 27.3187C24.2561 27.7577 23.6616 28 23.0292 28Z", fill: "url(#paint0_linear)" }, void 0), _jsx("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M8.65385 41H39.3462C41.3609 41 43 39.3609 43 37.3462V6.65385C43 4.63912 41.3609 3 39.3462 3H8.65385C6.63912 3 5 4.63912 5 6.65385V37.3462C5 39.3609 6.63912 41 8.65385 41ZM6.46154 6.65385C6.46154 5.44515 7.44515 4.46154 8.65385 4.46154H39.3462C40.5548 4.46154 41.5385 5.44515 41.5385 6.65385V37.3462C41.5385 38.5548 40.5548 39.5385 39.3462 39.5385H8.65385C7.44515 39.5385 6.46154 38.5548 6.46154 37.3462V6.65385ZM11.25 37H36.75C37.9905 37 39 35.9905 39 34.75V9.25C39 8.0095 37.9905 7 36.75 7H11.25C10.0095 7 9 8.0095 9 9.25V34.75C9 35.9905 10.0095 37 11.25 37ZM10.5 9.25C10.5 8.83675 10.836 8.5 11.25 8.5H36.75C37.164 8.5 37.5 8.83675 37.5 9.25V34.75C37.5 35.1632 37.164 35.5 36.75 35.5H11.25C10.836 35.5 10.5 35.1632 10.5 34.75V9.25ZM14.5 16H13V12.25C13 11.5606 13.6727 11 14.5 11H19V12.25H14.5V16ZM30 33H33.75C34.4394 33 35 32.3272 35 31.5V27H33.75V31.5H30V33Z", fill: "url(#paint1_linear)" }, void 0), _jsxs("defs", { children: [_jsxs("linearGradient", __assign({ id: "paint0_linear", x1: "25", y1: "15.25", x2: "25", y2: "28.588", gradientUnits: "userSpaceOnUse" }, { children: [_jsx("stop", { "stop-color": "#8DB5B5" }, void 0), _jsx("stop", { offset: "1", "stop-color": "#A7E2D4" }, void 0)] }), void 0), _jsxs("linearGradient", __assign({ id: "paint1_linear", x1: "24", y1: "2.21875", x2: "24", y2: "41.9875", gradientUnits: "userSpaceOnUse" }, { children: [_jsx("stop", { "stop-color": "#337A7A" }, void 0), _jsx("stop", { offset: "1", "stop-color": "#61CCB2" }, void 0)] }), void 0)] }, void 0)] }), void 0)); };
