import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, InputField, LinkButton } from "pokko-shared";
import { strings } from "strings";

export type AccountCreateBasicInput = {
  name: string;
};

export type AccountCreateBasicProps = {
  loading: boolean;
  onSubmit: (values: AccountCreateBasicInput) => void;
};

export const AccountCreateBasic: React.FC<AccountCreateBasicProps> = ({
  loading,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AccountCreateBasicInput>({});

  return (
    <div className="account-create-basic__container">
      <div className="account-create__header">
        <h1 className="h2">Account information</h1>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="account-create-basic__content"
      >
        <InputField
          label="Account name"
          placeholder="Name of your account"
          autoFocus
          state={errors.name ? "danger" : undefined}
          disabled={loading}
          error={errors.name}
          {...register("name", { required: strings.required })}
          help="Use a name that describes the account or what you will be using it for"
        />

        <div className="account-create-basic__actions">
          <Button kind="primary" type="submit">
            Submit
          </Button>
          <LinkButton kind="secondary" to="..">
            Cancel
          </LinkButton>
        </div>
      </form>
    </div>
  );
};
