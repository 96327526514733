import * as React from "react";
import cx from "classnames";
import { Helmet } from "react-helmet";
import {
  SimpleNavbar,
  SimpleNavbarProps,
} from "../../elements/navbar/simple/SimpleNavbar";

export type SimpleLayoutProps = {
  white?: boolean;
  navbar?: SimpleNavbarProps;
};

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({
  children,
  white,
  navbar,
}) => (
  <div className={cx("simple-layout")}>
    <Helmet bodyAttributes={{ class: cx({ "is-white": white }) }} />
    <SimpleNavbar {...navbar} />
    <main>{children}</main>
  </div>
);
