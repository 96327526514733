import * as React from "react";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import {
  ProjectSettings,
  ProjectSettingsInput,
} from "components/projects/settings/ProjectSettings";
import { useAccount } from "routes/account";
import { Navigate, useNavigate, useParams } from "react-router";
import { useGetProjectQuery, useUpdateProjectMutation } from "api/graphql";

export const ProjectSettingsPage: React.FC = () => {
  const { account } = useAccount();
  const { project } = useParams();
  const navigate = useNavigate();
  const query = useGetProjectQuery({
    variables: { key: project!, account: account.key },
  });
  const [update, updateStatus] = useUpdateProjectMutation();
  const [archive, archiveStatus] = useUpdateProjectMutation();

  if (query.loading) {
    return null;
  }

  if (!query.data?.project) {
    return <Navigate to={`/accounts/${account.key}`} />;
  }

  const { project: entity } = query.data;

  const handleUpdate = async (patch: ProjectSettingsInput) => {
    await update({ variables: { id: entity.id, patch } });

    navigate(`/accounts/${account.key}`);
  };

  const handleArchive = async () => {
    await archive({
      variables: {
        id: entity.id,
        patch: { archived: !Boolean(entity.deletedAt) },
      },
    });

    navigate(`/accounts/${account.key}`);
  };

  return (
    <SimpleLayout>
      <ProjectSettings
        archive={[handleArchive, archiveStatus]}
        archived={Boolean(entity.deletedAt)}
        name={entity.name}
        save={[handleUpdate, updateStatus]}
        value={{ name: entity.name }}
      />
    </SimpleLayout>
  );
};
