import * as React from "react";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import {
  AccountSettings,
  AccountSettingsInput,
} from "components/accounts/settings/AccountSettings";
import { useAccount } from "..";
import {
  useListAccountUsersQuery,
  useUpdateAccountMutation,
  useRemoveAccountUserMutation,
} from "api/graphql";

export const AccountSettingsPage: React.FC = () => {
  const { account } = useAccount();

  const query = useListAccountUsersQuery({
    variables: {
      key: account.key!,
    },
  });

  const [updateAccount, updateAccountStatus] = useUpdateAccountMutation();
  const [removeUser] = useRemoveAccountUserMutation();

  const handleSave = async (values: AccountSettingsInput) => {
    await updateAccount({
      variables: {
        id: account.id!,
        patch: {
          name: values.name,
        },
      },
    });
  };

  const handleRemoveUser = async (id: string) => {
    await removeUser({ variables: { account: account.id!, user: id } });
    query.refetch();
  };

  return (
    <SimpleLayout>
      <AccountSettings
        accountKey={account.key!}
        name={account.name!}
        value={{
          name: account.name!,
        }}
        save={[handleSave, updateAccountStatus]}
        onRemoveUser={handleRemoveUser}
        users={
          query.data?.account?.users?.map((ent) => ({
            id: ent.id,
            name: ent.name,
            email: ent.email,
          })) ?? []
        }
      />
    </SimpleLayout>
  );
};
