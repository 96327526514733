import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputField, Message, Button } from "pokko-shared";

import { FormProps } from "../defaults";

export type ResetStep1Input = {
  email: string;
};

export type ResetStep1FormProps = FormProps<ResetStep1Input> & {
  success: boolean;
};

export const PasswordResetStep1Form: React.FC<ResetStep1FormProps> = ({
  defaultValues,
  error,
  loading,
  success,
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ResetStep1Input>({
    defaultValues,
  });

  React.useEffect(() => reset(), [reset]);

  return (
    <div className="entry-form">
      <h2 className="h2 entry-form__heading">Reset your password</h2>

      <p className="entry-form__intro">
        Enter the email you used when you joined and we'll send you a link to
        reset your password.
      </p>

      <form className="entry-form__input" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="email"
          placeholder="Email"
          disabled={loading}
          error={errors.email}
          autoFocus
          {...register("email", {
            required: "This is a required field",
            pattern: {
              value: /[^@]+@[^.]+\..+/,
              message: "The value you have entered is invalid",
            },
          })}
        />

        {error ? (
          <div className="entry-form__message">
            <Message size="small" colour="danger">
              <p>{error}</p>
            </Message>
          </div>
        ) : null}

        {success ? (
          <div className="entry-form__message">
            <Message size="small" colour="success">
              <p>Email sent</p>
            </Message>
          </div>
        ) : null}

        <Button kind="primary" loading={loading} type="submit">
          Send link
        </Button>
      </form>

      <div className="entry-form__footer">
        <small>
          Go back to the <Link to="/login">login page</Link>
        </small>
      </div>
    </div>
  );
};
