import * as React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  SelectField,
  InputField,
  CheckboxField,
  Icons,
} from "pokko-shared";
import { CreateProjectInput, Region } from "../ProjectCreate";
import { strings } from "strings";

export type ImportProjectInput = CreateProjectInput & {
  source: string;
  config: {
    contentful?: {
      space: string;
      accessToken: string;
      environment: string;
      skipMediaUpload: boolean;
    };
  };
};

export type ProjectCreateImportProps = {
  loading: boolean;
  onSubmit: (values: ImportProjectInput) => void;
};

export const ProjectCreateImport: React.FC<
  ProjectCreateImportProps & { regions: Region[]; onBack: () => void }
> = ({ onSubmit, regions, loading, onBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ImportProjectInput>({
    defaultValues: {
      region: "au-syd1",
      config: { contentful: { skipMediaUpload: false } },
    },
  });

  const source = watch("source");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Button
          small
          kind="tertiary"
          icons={{ left: <Icons.BackIcon /> }}
          onClick={onBack}
        >
          Back
        </Button>
      </div>
      <span className="header">Import from somewhere else</span>
      <p>
        If you have a project in another platform we have automated the process
        of bringing it across.
      </p>
      <SelectField
        label="Region"
        error={errors.region}
        {...register("region", { required: strings.required })}
      >
        {regions.map((ent) => (
          <option key={ent.id} value={ent.id}>
            {ent.name} ({ent.id})
          </option>
        ))}
      </SelectField>
      <InputField
        label="Name"
        error={errors.name}
        {...register("name", { required: strings.required })}
      />
      <SelectField
        label="Source"
        error={errors.source}
        {...register("source", { required: strings.required })}
      >
        <option value="">Select...</option>
        <option value="contentful">Contentful</option>
        {/* <option value="datocms">DatoCMS</option>
        <option value="sitecore">Sitecore</option> */}
      </SelectField>
      {source ? (
        source === "contentful" ? (
          <>
            <span className="header">Contentful</span>
            <InputField
              label="Space"
              error={errors.config?.contentful?.space}
              {...register("config.contentful.space", {
                required: strings.required,
              })}
            />
            <InputField
              label="Environment"
              error={errors.config?.contentful?.environment}
              {...register("config.contentful.environment", {
                required: strings.required,
              })}
            />
            <InputField
              label="Access token"
              error={errors.config?.contentful?.accessToken}
              {...register("config.contentful.accessToken", {
                required: strings.required,
              })}
            />
            <CheckboxField
              id="skip_media_upload"
              label="Skip media upload"
              help="Media items will be created and referenced, but source media will not be imported. Useful for test runs."
              {...register("config.contentful.skipMediaUpload")}
            />
          </>
        ) : (
          <p>This platform is not yet supported.</p>
        )
      ) : null}
      <Button kind="primary" type="submit" loading={loading}>
        Create
      </Button>
      <p>
        <small>
          If your platform isn't listed,{" "}
          <a href="mailto:support@pokko.io?subject=Project import">
            get in touch
          </a>{" "}
          and we'll see what we can do.
        </small>
      </p>
    </form>
  );
};
