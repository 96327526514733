export const EmptyIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.75 12.5H81.25V21.875H18.75V12.5Z"
      fill="url(#EmptyIcon0_linear)"
    />
    <path
      d="M79.6875 9.375H20.3125C17.7281 9.375 15.625 11.4781 15.625 14.0625V85.9375C15.625 88.5219 17.7281 90.625 20.3125 90.625H79.6875C82.2719 90.625 84.375 88.5219 84.375 85.9375V14.0625C84.375 11.4781 82.2719 9.375 79.6875 9.375ZM20.3125 12.5H79.6875C80.5484 12.5 81.25 13.2 81.25 14.0625V21.875H18.75V14.0625C18.75 13.2 19.4516 12.5 20.3125 12.5ZM79.6875 87.5H20.3125C19.4516 87.5 18.75 86.8 18.75 85.9375V25H81.25V85.9375C81.25 86.8 80.5484 87.5 79.6875 87.5Z"
      fill="url(#EmptyIcon1_linear)"
    />
    <path
      d="M50.0422 33.7837C38.7922 33.7837 29.7297 42.8462 29.7297 54.0962C29.7297 65.3462 38.7922 74.4087 50.0422 74.4087C61.2922 74.4087 70.3547 65.3462 70.3547 54.0962C70.3547 42.8462 61.2922 33.7837 50.0422 33.7837ZM50.0422 71.2837C40.511 71.2837 32.8547 63.6274 32.8547 54.0962C32.8547 44.5649 40.511 36.9087 50.0422 36.9087C59.5735 36.9087 67.2297 44.5649 67.2297 54.0962C67.2297 63.6274 59.5735 71.2837 50.0422 71.2837Z"
      fill="url(#EmptyIcon2_linear)"
    />
    <path
      d="M51.478 44.5945H48.353V52.407H40.5405V55.532H48.353V63.3445H51.478V55.532H59.2905V52.407H51.478V44.5945Z"
      fill="url(#EmptyIcon3_linear)"
    />
    <defs>
      <linearGradient
        id="EmptyIcon0_linear"
        x1="50"
        y1="11.9141"
        x2="50"
        y2="22.3344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BFE0DD" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
      <linearGradient
        id="EmptyIcon1_linear"
        x1="50"
        y1="8.78906"
        x2="50"
        y2="91.6406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008478" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
      <linearGradient
        id="EmptyIcon2_linear"
        x1="50.0422"
        y1="33.4907"
        x2="50.0422"
        y2="74.9165"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008478" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
      <linearGradient
        id="EmptyIcon3_linear"
        x1="49.9155"
        y1="44.4593"
        x2="49.9155"
        y2="63.5789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008478" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
    </defs>
  </svg>
);

export const ImportIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.7061 75V76.5625C70.7061 79.1469 68.6029 81.25 66.0186 81.25H22.2686C19.6842 81.25 17.5811 79.1469 17.5811 76.5625V17.1875C17.5811 14.6031 19.6842 12.5 22.2686 12.5H54.0561C54.4061 12.5 54.7467 12.5469 55.0811 12.6219V20.3125C55.0811 24.6203 58.5857 28.125 62.8936 28.125H70.5857C70.6592 28.4578 70.7061 28.8 70.7061 29.15V40.625H73.8311V29.15C73.8311 27.0625 73.017 25.1 71.542 23.6266L59.5795 11.6641C58.1045 10.1891 56.142 9.375 54.0545 9.375H22.2686C17.9607 9.375 14.4561 12.8797 14.4561 17.1875V82.8125C14.4561 87.1203 17.9607 90.625 22.2686 90.625H66.0186C70.3264 90.625 73.8311 87.1203 73.8311 82.8125V76.5625V75V59.375H70.7061V75ZM68.4967 25H62.8936C60.3092 25 58.2061 22.8969 58.2061 20.3125V14.7094L68.4967 25ZM66.0186 87.5H22.2686C19.6842 87.5 17.5811 85.3969 17.5811 82.8125V82.7734C18.8904 83.7641 20.5029 84.375 22.2686 84.375H66.0186C67.7842 84.375 69.3967 83.7641 70.7061 82.7734V82.8125C70.7061 85.3969 68.6029 87.5 66.0186 87.5Z"
      fill="url(#ImportIcon0_linear)"
    />
    <path
      d="M85.8352 43.75H59.7685V40.6828C59.7685 39.414 59.0279 38.2687 57.8826 37.7656C56.7467 37.2672 55.4685 37.4828 54.5482 38.3234L45.7123 46.4297C44.7138 47.3468 44.142 48.6484 44.142 50.0015C44.142 51.3531 44.7138 52.6531 45.7107 53.5672L54.5545 61.6781C55.1451 62.2203 55.8842 62.5015 56.6373 62.5015C57.0576 62.5015 57.481 62.4125 57.8873 62.2343C59.0295 61.7359 59.7685 60.5906 59.7685 59.3234V56.25H85.8352C87.5524 56.25 88.9493 54.8484 88.9493 53.125V46.875C88.9493 45.1515 87.5524 43.75 85.8352 43.75ZM56.6435 53.125L56.6654 59.3765L47.8217 51.2656C47.4638 50.9375 47.267 50.489 47.267 50.0031C47.267 49.514 47.4654 49.064 47.8248 48.7343L56.6435 40.6828V46.875H85.8243L85.8352 53.125H56.6435Z"
      fill="url(#ImportIcon1_linear)"
    />
    <path
      d="M33.2061 15.625H22.2686C21.4061 15.625 20.7061 16.325 20.7061 17.1875V28.125L33.2061 15.625Z"
      fill="url(#ImportIcon2_linear)"
    />
    <defs>
      <linearGradient
        id="ImportIcon0_linear"
        x1="44.1436"
        y1="8.78906"
        x2="44.1436"
        y2="91.6406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008478" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
      <linearGradient
        id="ImportIcon1_linear"
        x1="66.5456"
        y1="37.3204"
        x2="66.5456"
        y2="62.814"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008478" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
      <linearGradient
        id="ImportIcon2_linear"
        x1="26.9561"
        y1="14.8438"
        x2="26.9561"
        y2="28.7375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BFE0DD" />
        <stop offset="1" stopColor="#80C1BB" />
      </linearGradient>
    </defs>
  </svg>
);

export const TemplateIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M18.75 12.5H81.25V21.875H18.75V12.5Z"
        fill="url(#TemplateIcon0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3125 9.375H79.6875C82.2719 9.375 84.375 11.4781 84.375 14.0625V85.9375C84.375 88.5219 82.2719 90.625 79.6875 90.625H20.3125C17.7281 90.625 15.625 88.5219 15.625 85.9375V14.0625C15.625 11.4781 17.7281 9.375 20.3125 9.375ZM79.6875 12.5H20.3125C19.4516 12.5 18.75 13.2 18.75 14.0625V21.875H81.25V14.0625C81.25 13.2 80.5484 12.5 79.6875 12.5ZM20.3125 87.5H79.6875C80.5484 87.5 81.25 86.8 81.25 85.9375V25H18.75V85.9375C18.75 86.8 19.4516 87.5 20.3125 87.5ZM28.125 28.125H71.875C73.5984 28.125 75 29.5266 75 31.25V34.375C75 36.0984 73.5984 37.5 71.875 37.5H28.125C26.4016 37.5 25 36.0984 25 34.375V31.25C25 29.5266 26.4016 28.125 28.125 28.125ZM65.625 34.375V31.25H34.375V34.375H65.625ZM31.25 31.25H28.125V34.375H31.25V31.25ZM68.75 34.375H71.875V31.25H68.75V34.375ZM43.75 40.625H28.125C26.4016 40.625 25 42.0266 25 43.75V57.8125V59.375V62.5C25 64.2234 26.4016 65.625 28.125 65.625H43.75C45.4734 65.625 46.875 64.2234 46.875 62.5V59.375V56.25V43.75C46.875 42.0266 45.4734 40.625 43.75 40.625ZM43.75 62.5H28.125V59.375H43.75V62.5ZM28.125 56.25V43.75H43.75V56.25H28.125ZM56.25 40.625H71.875C73.5984 40.625 75 42.0266 75 43.75V56.25V59.375V62.5C75 64.2234 73.5984 65.625 71.875 65.625H56.25C54.5266 65.625 53.125 64.2234 53.125 62.5V59.375V57.8125V43.75C53.125 42.0266 54.5266 40.625 56.25 40.625ZM56.25 62.5H71.875V59.375H56.25V62.5ZM56.25 43.75V56.25H71.875V43.75H56.25ZM25 68.75H46.875V71.875H25V68.75ZM75 68.75H53.125V71.875H75V68.75ZM25 15.625H34.375V18.75H25V15.625ZM46.875 15.625H37.5V18.75H46.875V15.625ZM28.125 75H43.75V78.125H28.125V75ZM71.875 75H56.25V78.125H71.875V75Z"
        fill="url(#TemplateIcon1_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="TemplateIcon0_linear"
        x1="50"
        y1="11.9141"
        x2="50"
        y2="22.3344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E4E4" />
        <stop offset="1" stopColor="#C0C0C0" />
      </linearGradient>
      <linearGradient
        id="TemplateIcon1_linear"
        x1="50"
        y1="8.78906"
        x2="50"
        y2="91.6406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" />
        <stop offset="1" stopColor="#BBBBBB" />
      </linearGradient>
    </defs>
  </svg>
);
