var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var ShareIcon = function () { return (_jsxs("svg", __assign({ className: "icon__share", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M17 12.561V17H3V3", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinejoin: "round" }, void 0), _jsx("path", { d: "M14 8L16 6L14 4", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0), _jsx("path", { d: "M16 6.0048H13.5013C7.35161 5.8597 5.9011 9.01544 6.00511 14", stroke: "black", strokeWidth: "1.25", strokeMiterlimit: "10", strokeLinecap: "round" }, void 0)] }), void 0)); };
